/**
 *
 * Base Output Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Record, Seq, OrderedMap } from "immutable";
import { compose, prop, ascend } from "ramda";
export const OUTPUT_STATUSES = {
  ON: "STEADY",
  OFF: "OFF"
};
export const createOutputPropsFromJson = ({ name, number, status }) => ({
  name,
  isOn: status === OUTPUT_STATUSES.ON,
  number: parseInt(number, 10)
});
const createOutput = Record({
  name: "",
  isOn: false,
  number: 0
});
export const createOutputFromJson = compose(
  createOutput,
  createOutputPropsFromJson
);
export const createOutputsFromJson = json =>
  Seq(json)
    .map(createOutputFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, output) => acc.set(output.number, output), OrderedMap());
