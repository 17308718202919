/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 *
 */
import usersPageReducer from "pages/UsersPage_Legacy/reducer";
import { isAdminRoute, siteIdFromPathname } from "paths";
import { LOCATION_CHANGE } from "react-router-redux";
import { combineReducers } from "redux-immutable";
import {
  ADD_SYSTEM_TO_STORE,
  ADMIN_BUTTON_CLICKED,
  ADMIN_CUSTOMER_BUTTON_CLICKED,
  SYSTEM_BUTTON_CLICKED,
} from "store/app/constants";
import appReducer from "store/app/reducer";
import { selectSystemsListVisible } from "store/app/selectors";
import {
  RECEIVE_AUTHENTICATED_USER_DATA,
  RECEIVE_ONBOARD_USER_DATA,
} from "store/auth/constants";
import authReducer from "store/auth/reducer";
import { selectDefaultAdminCustomerId } from "store/auth/selectors";
import {
  selectActiveSystem,
  selectIsTempDealerUser,
  selectLocationBeforeTransitions,
  selectSelectedCustomerForAdminSection,
  selectSelectedCustomerIdForAdminSection,
  selectUserForActiveSystem,
  selectUsersByCustomerId,
} from "store/common/selectors";
import confirmReducer from "store/confirm/reducer";
import dealerReducer from "store/dealer/reducer";
import notificationsReducer from "store/notifications/reducer";
import pendingConfirmReducer from "store/pendingConfirm/reducer";
import routeReducer from "store/route/reducer";
import settingsReducer from "store/settings/reducer";
import { initialState as initialSystemGroupsState } from "store/systemGroups/reducer";
import { SYSTEM_SESSION_INITIALIZED } from "store/systems/constants";
import {
  RECIEVE_ARMED_STATUS,
  REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS,
} from "store/systems/constants/arming";
import systemsReducer from "store/systems/reducer";
import { initialState as initialArmingState } from "store/systems/reducer/arming";
import {
  selectAccessibleSystemsForArming,
  selectActiveSystemId,
  selectCanViewRestrictedPages,
  selectSystem,
} from "store/systems/selectors";
import usersReducer from "store/users/reducer";
import { immutableWithMutations } from "utils";

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

export default function createReducer(asyncReducers) {
  const defaultReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    users: usersReducer,
    usersPage: usersPageReducer,
    dealer: dealerReducer,
    systems: systemsReducer,
    route: routeReducer,
    pendingConfirm: pendingConfirmReducer,
    confirm: confirmReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    ...asyncReducers,
  });

  return function rootReducer(state, action) {
    const nextState = defaultReducer(state, action);

    switch (action.type) {
      case ADD_SYSTEM_TO_STORE:
        return nextState.withMutations((mutableState) => {
          mutableState.setIn(
            ["systems", "byId", action.systemId],
            action.system
          );
        });

      case SYSTEM_BUTTON_CLICKED: {
        const { systemId } = action;
        const prevActiveSystemId = selectActiveSystemId(state);
        const systemsListVisible = selectSystemsListVisible(state);
        const system = selectSystem(nextState, { systemId });

        if (
          (!system || (!system.get("isX1") && prevActiveSystemId !== systemId)) &&
          !systemsListVisible
        ) {
          return nextState.setIn(["app", "systemsListVisible"], true);
        } else if (system && selectCanViewRestrictedPages(state, { system })) {
          return nextState.withMutations((mutableNextState) =>
            mutableNextState
              .update(
                "app",
                immutableWithMutations((mutableAppState) =>
                  mutableAppState
                    .set("systemsListVisible", false)
                    .set("systemsListQuery", "")
                )
              )
              .updateIn(["systems", "byId", systemId], (systemState) =>
                systemState.merge({
                  requestingArmingStatus: true,
                  armedStatusError: null,
                  badZones: initialArmingState.badZones,
                })
              )
          );
        }

        return nextState;
      }

      case ADMIN_BUTTON_CLICKED: {
        const userForActiveSystem = selectUserForActiveSystem(state);

        return nextState.update(
          "app",
          immutableWithMutations((mutableAppState) =>
            mutableAppState
              .set("systemsListVisible", true)
              .update(
                "selectedCustomerForAdminSection",
                (selectedCustomerForAdminSection) =>
                  userForActiveSystem?.role.toLowerCase() === "admin"
                    ? Number(userForActiveSystem.accessibleId)
                    : selectedCustomerForAdminSection ??
                      selectDefaultAdminCustomerId(nextState)
              )
          )
        );
      }

      case ADMIN_CUSTOMER_BUTTON_CLICKED: {
        const selectedCustomerIdForAdminSection = selectSelectedCustomerIdForAdminSection(
          state
        );

        return action.customerId !== selectedCustomerIdForAdminSection
          ? nextState.update(
              "systemGroups",
              (systemGroupsState) =>
                systemGroupsState && initialSystemGroupsState
            )
          : nextState;
      }

      case RECEIVE_AUTHENTICATED_USER_DATA: {
        const selectedCustomerIdForAdminSection = selectSelectedCustomerIdForAdminSection(
          nextState
        );
        const usersByCustomerId = selectUsersByCustomerId(nextState);

        if (
          selectedCustomerIdForAdminSection &&
          !usersByCustomerId.has(selectedCustomerIdForAdminSection)
        ) {
          return nextState.setIn(
            ["app", "selectedCustomerForAdminSection"],
            null
          );
        }

        return nextState;
      }

      case RECEIVE_ONBOARD_USER_DATA: {
        const pathname =
          selectLocationBeforeTransitions(state)?.pathname ?? "/";
        const activeSystem = selectActiveSystem(nextState);

        if (activeSystem) {
          const canView = selectCanViewRestrictedPages(nextState, {
            system: activeSystem,
          });
          return canView
            ? nextState.setIn(["app", "systemsListVisible"], false)
            : nextState;
        }

        if (siteIdFromPathname(pathname)) {
          return nextState.setIn(["app", "systemsListVisible"], false);
        }

        const selectedCustomerForAdminSection = selectSelectedCustomerForAdminSection(
          nextState
        );
        if (isAdminRoute(pathname)) {
          return nextState.update(
            "app",
            immutableWithMutations((mutableAppState) =>
              mutableAppState
                .update(
                  "selectedCustomerForAdminSection",
                  (selectedCustomerIdForAdminSection) =>
                    selectedCustomerIdForAdminSection &&
                    selectedCustomerForAdminSection?.role.toLowerCase() ===
                      "admin"
                      ? Number(selectedCustomerForAdminSection.accessibleId)
                      : selectDefaultAdminCustomerId(nextState)
                )
                .set("systemsListVisible", true)
            )
          );
        }

        return nextState;
      }

      case SYSTEM_SESSION_INITIALIZED:
        return selectSystemsListVisible(state)
          ? nextState.setIn(["app", "systemsListVisible"], false)
          : nextState;

      case REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS: {
        const accessibleSystemsForArming = selectAccessibleSystemsForArming(
          nextState
        );
        return state.updateIn(["systems", "byId"], (systemsById) =>
          systemsById.map((system) =>
            accessibleSystemsForArming.has(system.id)
              ? system.setIn(["arming", "requestingArmingStatus"], true)
              : system
          )
        );
      }

      case RECIEVE_ARMED_STATUS: {
        return !selectSystemsListVisible(state) &&
          !action.userCodeValidated &&
          !selectIsTempDealerUser(nextState)
          ? nextState.setIn(["app", "systemsListVisible"], true)
          : nextState;
      }

      case LOCATION_CHANGE: {
        const { pathname } = action.payload;
        const siteId = siteIdFromPathname(pathname);
        const systemsListVisible = selectSystemsListVisible(nextState);
        if (siteId && systemsListVisible) {
          return nextState.setIn(["app", "systemsListVisible"], false);
        }

        return nextState;
      }

      default:
        return nextState;
    }
  };
}
