import { Record } from "immutable";

export const LEVEL = {
  MIN: 0,
  MAX: 99
};

const ZwaveAppliance = Record({
  nodeSubtype: "STANDARD",
  cacheStatus: "PENDING",
  number: 0,
  errorMessage: "",
  name: "",
  metered: false,
  nodeType: "APPLIANCE",
  level: 0
});

export default ZwaveAppliance;
