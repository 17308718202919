/**
 *
 * PendingConfirm Model
 * @author Chad Watson
 *
 *
 */
import { Record } from "immutable";
import Maybe from "data.maybe";
export const createPendingConfirm = Record({
  message: "",
  messageValues: {},
  onConfirm: Maybe.Nothing(),
  onCancel: Maybe.Nothing(),
  active: false
});
