import {
  expectSuccessWithJsonBody,
  forkAsPromise,
} from "common/utils/secure-ui/utils/src";
import Maybe from "data.maybe";
import { Set } from "immutable";
import { always, compose } from "ramda";
import { createKeyedResource } from "react-remote-resource";
import { odataEventHistoriesEndpoint, odataEndpoint } from "utils/endpoints";
import { useAuthTokenForSystem, useAuthTokenForSite } from "./auth-token";
import { getJson } from "./utils/json";

const getResolvedInvalidUserCodeEvents = (authToken, systemId) =>
  expectSuccessWithJsonBody(
    ({ value }) => Maybe.fromNullable(value),
    getJson({
      url: odataEventHistoriesEndpoint(
        `/api/v1/panels(${systemId})/vk.GetScapiClientEvents()`
      ),
      authToken,
      params: { $filter: "category eq 1", $select: "data_bigint" },
    })
  ).fold(always(Set()), (value) =>
    value.reduce((acc, item) => acc.add(item.data_bigint), Set())
  );

const getSiteResolvedInvalidUserCodeEvents = (authToken, siteId) =>
  expectSuccessWithJsonBody(
    ({ value }) => Maybe.fromNullable(value),
    getJson({
      url: odataEndpoint(`/api/v1/sites(${siteId})/vk.GetScapiClientEvents()`),
      authToken,
      params: { $filter: "category eq 1", $select: "data_bigint" },
    })
  ).fold(always(Set()), (value) =>
    value.reduce((acc, item) => acc.add(item.data_bigint), Set())
  );

const resolvedInvalidUserCodeEventsResource = createKeyedResource(
  compose(forkAsPromise, getResolvedInvalidUserCodeEvents),
  (_, systemId) => systemId
);

export default resolvedInvalidUserCodeEventsResource;

export const resolvedSiteInvalidUserCodeEventsResource = createKeyedResource(
  compose(forkAsPromise, getSiteResolvedInvalidUserCodeEvents),
  (_, siteId) => siteId
);

export const useResolvedInvalidUserCodeEvents = (systemId) =>
  resolvedInvalidUserCodeEventsResource.useState(
    useAuthTokenForSystem(systemId),
    systemId
  );

export const useSiteResolvedInvalidUserCodeEvents = (siteId) =>
  resolvedSiteInvalidUserCodeEventsResource.useState(
    useAuthTokenForSite(siteId),
    siteId
  );

export const useInvalidUserCodeEventIsResolved = (
  systemId,
  eventId,
  isX1 = false
) => {
  const getXrResolvedInvalidUserCodes = useResolvedInvalidUserCodeEvents;
  const getX1ResolvedInvalidUserCodes = useSiteResolvedInvalidUserCodeEvents;
  const [eventIds] = isX1
    ? getX1ResolvedInvalidUserCodes(systemId)
    : getXrResolvedInvalidUserCodes(systemId);

  //Sometimes the eventId type is a string of numbers
  //This fixes Issue CVA-1075
  return eventIds.has(Number(eventId));
};

export const useGetResolvedInvalidUserCodeEvents = (systemId) =>
  getResolvedInvalidUserCodeEvents(useAuthTokenForSystem(systemId), systemId);
