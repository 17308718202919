/**
 *
 * Panel Capabilities model
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { Record } from "immutable";
export const createPanelCapabilities = Record({
  generalTwilightScheduling: false,
  instantArming: false,
  singleAreaSystem: false,
  sendCodesToLocks: Maybe.Nothing(),
  temporaryScheduling: false,
  userProgrammableActions: false,
  enhancedAppSupport: false,
  inactiveUser: false
});
export const createSendCodesToLocksUserNumberRange = Record({
  minUserNumber: 0,
  maxUserNumber: 0
});
export const createPanelCapabilitiesFromJson = json =>
  createPanelCapabilities({
    generalTwilightScheduling: json.general_twilight_scheduling,
    instantArming: json.instant_arming,
    singleAreaSystem: json.single_area_system,
    sendCodesToLocks: Maybe.fromNullable(
      json.send_codes_to_locks.user_number_range
    )
      .map(({ min, max }) => ({
        minUserNumber: min,
        maxUserNumber: max
      }))
      .map(createSendCodesToLocksUserNumberRange),
    temporaryScheduling: json.temporary_scheduling,
    userProgrammableActions: json.user_actions,
    enhancedAppSupport: json.enhanced_app_support,
    inactiveUser: json.inactive_user
  });
