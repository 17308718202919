/**
 *
 * SchedulesTimes Messages
 * @author Chad Watson
 *
 * This contains all the text for page SchedulesTimes component.
 *
 */
import { defineMessages } from "react-intl";
import { SLOT_TYPE_OPTIONS, SLOT_END_TYPE_OPTIONS } from "./translate";

export default defineMessages({
  [SLOT_END_TYPE_OPTIONS.NEXT_DAY]: {
    id: "app.models.schedules.translate.NEXT_DAY",
    defaultMessage: "Next day"
  },
  [SLOT_END_TYPE_OPTIONS.SAME_DAY]: {
    id: "app.models.schedules.translate.SAME_DAY",
    defaultMessage: "Same day"
  },
  [SLOT_TYPE_OPTIONS.EVERY_DAY]: {
    id: "app.models.schedules.translate.EVERY_DAY",
    defaultMessage: "Every day"
  },
  [SLOT_TYPE_OPTIONS.WEEKDAYS]: {
    id: "app.models.schedules.translate.WEEKDAYS",
    defaultMessage: "Weekdays"
  },
  [SLOT_TYPE_OPTIONS.WEEKENDS]: {
    id: "app.models.schedules.translate.WEEKENDS",
    defaultMessage: "Weekends"
  },
  [SLOT_TYPE_OPTIONS.SUN]: {
    id: "app.models.schedules.translate.SUN",
    defaultMessage: "Sunday"
  },
  [SLOT_TYPE_OPTIONS.MON]: {
    id: "app.models.schedules.translate.MON",
    defaultMessage: "Monday"
  },
  [SLOT_TYPE_OPTIONS.TUE]: {
    id: "app.models.schedules.translate.TUE",
    defaultMessage: "Tuesday"
  },
  [SLOT_TYPE_OPTIONS.WED]: {
    id: "app.models.schedules.translate.WED",
    defaultMessage: "Wednesday"
  },
  [SLOT_TYPE_OPTIONS.THU]: {
    id: "app.models.schedules.translate.THU",
    defaultMessage: "Thursday"
  },
  [SLOT_TYPE_OPTIONS.FRI]: {
    id: "app.models.schedules.translate.FRI",
    defaultMessage: "Friday"
  },
  [SLOT_TYPE_OPTIONS.SAT]: {
    id: "app.models.schedules.translate.SAT",
    defaultMessage: "Saturday"
  },
  minutesBefore: {
    id: "app.models.schedules.translate.minutesBefore",
    defaultMessage: "{amount} min before"
  },
  minutesAfter: {
    id: "app.models.schedules.translate.minutesAfter",
    defaultMessage: "{amount} min after"
  },
  noOffset: {
    id: "app.models.schedules.translate.noOffset",
    defaultMessage: "No offset"
  },
  sunrise: {
    id: "app.models.schedules.translate.SUNRISE",
    defaultMessage: "Sunrise"
  },
  sunset: {
    id: "app.models.schedules.translate.SUNSET",
    defaultMessage: "Sunset"
  }
});
