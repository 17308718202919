/**
 *
 * Base Zwave Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
export const defaultZwaveDeviceProps = {
  nodeSubtype: "STANDARD",
  cacheStatus: "PENDING",
  number: 0,
  errorMessage: "",
  name: "",
  metered: false
};
/**
 * Builds a base zwave model
 * @param  {object} light status from API JSON response
 * @return {object}
 */

export default ({ name, metered, number, ...json }) => ({
  nodeSubtype: json.node_subtype,
  nodeType: json.node_type,
  cacheStatus: json.cache_status || defaultZwaveDeviceProps.cacheStatus,
  number: parseInt(number, 10),
  errorMessage: json.error_message,
  name,
  metered
});
