/**
 *
 * Zone Information
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { OrderedMap, Record, Seq, Set } from "immutable";
import { any, compose, equals, has, prop } from "ramda";
import { parseStringList, safeArrayOfInts } from "utils";
export const STATUSES = {
  OPEN: "O",
  SHORT: "S",
  NORMAL: "N",
  BYPASSED: "X",
  LOW_BATTERY: "L",
  MISSING: "M",
  DEFAULT: "M",
};
const createZoneInformation = Record({
  number: 0,
  name: "",
  trafficEnabled: false,
  status: Maybe.Nothing(),
  type: "--",
  chimeSound: Maybe.Nothing(),
  armedAreas: "",
  doorPropped: false,
  areas: Set(),
});
const isExitZone = compose(equals("EX"), prop("tipe"));
const isNightZone = compose(equals("NT"), prop("tipe"));
const hasChimeSound = has("chime_snd");
const createAreasFromJson = compose(
  Set,
  safeArrayOfInts,
  parseStringList,
  prop("area_list")
);
export const createZoneInformationFromZoneInformationJson = (json) => {
  return createZoneInformation({
    number: parseInt(json.number, 10),
    // The default name is intentionally not internationalized
    // because it would take a decent amount of work to make that happen.
    // I've been told this is fine for now (- Chad)
    name: json.name ? json.name.trim() : `Zone ${parseInt(json.number, 10)}`,
    trafficEnabled: json.traffic_ct ? json.traffic_ct === "Y" : false,
    type: json.tipe,
    chimeSound: json.chime_snd
      ? hasChimeSound(json) && (isExitZone(json) || isNightZone(json))
        ? Maybe.of(json.chime_snd || "0")
        : Maybe.Nothing()
      : null,
    armedAreas: json.armd_areas ? json.armd_areas : [],
    areas: json.areas ? createAreasFromJson(json) : [],
    doorPropped: any(equals("D"), [
      json.ao_act_msg,
      json.do_act_msg,
      json.ds_act_msg,
    ]),
  });
};

export const createZoneInformationsFromZoneInformationsJson = (json) =>
  Seq(json)
    .map(
      compose(
        createZoneInformationFromZoneInformationJson,
        prop("zone_information")
      )
    )
    .sortBy(prop("name"))
    .reduce((acc, item) => acc.set(item.number, item), OrderedMap());
export const createZoneInformationFromZoneStatusJson = (json) =>
  createZoneInformation({
    number: parseInt(json.number, 10),
    name: json.name.trim(),
    trafficEnabled: json.traffic_ct === "Y",
    status: Maybe.of(json.status),
  });
export const createZoneInformationsFromZoneStatusesJson = (json) =>
  Seq(json)
    .map(prop("zone_status"))
    .filter(({ tipe }) => tipe === "L")
    .map(createZoneInformationFromZoneStatusJson)
    .sortBy(prop("name"))
    .reduce((acc, item) => acc.set(item.number, item), OrderedMap());
export const zoneInformationToJson = (zone) => ({
  chime_snd: zone.chimeSound.getOrElse("0"),
  armd_areas: zone.armedAreas,
});
