/**
 *
 * Manager Error Model
 * @author Matt Shaffer
 *
 */

/**
 * Builds a Manager Error model
 * @param  {object}
 * @return {object}
 */
export default ({
  unauthorized = false,
  message = {},
  code = null,
  data = {}
}) => ({
  unauthorized,
  message,
  code,
  data
});
