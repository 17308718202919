/**
 *
 * panelDemandInfo model
 * @author Chad Watson
 *
 */

import {
  compose,
  applySpec,
  prop,
  ifElse,
  has,
  always,
  pick,
  defaultTo,
  invoker,
  filter,
  map,
  both,
  sort
} from "ramda";
import moment from "moment";
import { toInt, prune } from "utils";

const toISOString = invoker(0, "toISOString");

const eventIsFuture = event => moment(event.EndPOD).isAfter(moment());

const startComparator = (a, b) => (a.start.isBefore(b.before) ? -1 : 1);

const dealerChargeFromJSON = applySpec({
  rate: prop("Rate")
});

const payServerResponseFromJSON = pick(["status", "error"]);

const rawEventId = compose(
  toInt,
  prop("Id")
);

const scheduledEventFromJSON = applySpec({
  id: rawEventId,
  active: prop("Active"),
  end: compose(
    moment,
    prop("EndPOD")
  ),
  start: compose(
    moment,
    prop("StartPOD")
  ),
  panelId: prop("PanelODId")
});

const scheduledEventsFromJSON = compose(
  sort(startComparator),
  map(scheduledEventFromJSON),
  filter(both(eventIsFuture, prop("Active")))
);

export const scheduledEventToApiObject = applySpec({
  Id: prop("id"),
  Active: compose(
    defaultTo(true),
    prop("active")
  ),
  EndPOD: compose(
    toISOString,
    prop("end")
  ),
  StartPOD: compose(
    toISOString,
    prop("start")
  ),
  PanelODId: prop("panelId")
});

const fromJSON = compose(
  prune,
  applySpec({
    id: prop("Id"),
    credits: compose(
      defaultTo(0),
      prop("Balance")
    ),
    canPod: prop("CanPOD"),
    dealerCharge: ifElse(
      has("DealerCharges"),
      compose(
        dealerChargeFromJSON,
        prop("DealerCharges")
      ),
      always(undefined)
    ),
    payServerResponse: ifElse(
      has("PaySrvResponse"),
      compose(
        payServerResponseFromJSON,
        prop("PaySrvResponse")
      ),
      always(undefined)
    ),
    events: ifElse(
      has("Events"),
      compose(
        scheduledEventsFromJSON,
        prop("Events")
      ),
      always(undefined)
    )
  })
);

export default fromJSON;

export const purchaseToApiObject = applySpec({
  PanelODId: prop("panelId"),
  DaysAdd: prop("daysAdd"),
  Rate: prop("rate"),
  Confirmed: prop("confirmed"),
  OrderId: prop("orderId"),
  Description: prop("description")
});
