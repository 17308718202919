import graphql from "babel-plugin-relay/macro";
import { Record } from "immutable";
import { readInlineData } from "react-relay";

export const createControlSystemServices = Record({
  armingAppEnabled: false,
  doorsEditingEnabled: false,
  favoriteEditingEnabled: false,
  fullAppEnabled: false,
  lightControlEnabled: false,
  lockControlEnabled: false,
  scheduleEditEnabled: false,
  thermostatControlEnabled: false,
  trafficCountEnabled: false,
  userCodeEditingEnabled: false,
  zwaveNodeEditingEnabled: false,
  sensorActivityEnabled: false,
  realTimeEventsEnabled: false,
  outputOptionsEditingEnabled: false,
  dmpVideoEnabled: false,
  varEnabled: false,
  iensoEnabled: false,
  hikvisionNvrEnabled: false,
  openEyeEnabled: false,
  eagleEyeEnabled: false,
  ringEnabled: false,
  storeUserCodes: false,
  doorAccessLevel: "",
});

export const DENIAL_ONLY = "DENIAL_ONLY";
export const ALL = "ALL";
export const NONE = "NONE";

export const createControlSystemServicesFromJson = (json) =>
  createControlSystemServices({
    armingAppEnabled: json.arming_app_enabled,
    doorsEditingEnabled: json.door_edit_enabled,
    favoriteEditingEnabled: json.favorite_edit_enabled,
    fullAppEnabled: json.full_app_enabled,
    lightControlEnabled: json.light_control_enabled,
    lockControlEnabled: json.lock_control_enabled,
    scheduleEditEnabled: json.schedule_edit_enabled,
    thermostatControlEnabled: json.thermostat_control_enabled,
    trafficCountEnabled: json.traffic_count_enabled,
    userCodeEditingEnabled: json.user_code_edit_enabled,
    zwaveNodeEditingEnabled: json.zwave_node_edit_enabled,
    sensorActivityEnabled: json.sensor_activity_enabled,
    realTimeEventsEnabled: json.real_time_events_enabled,
    outputOptionsEditingEnabled: json.output_option_edit_enabled,
    dmpVideoEnabled: json.premium_video_enabled,
    varEnabled: json.video_analytics_recorder_enabled,
    iensoEnabled: json.ienso_cameras_enabled,
    hikvisionNvrEnabled: json.standard_hikvision_nvr_enabled,
    openEyeEnabled: json.open_eye_enabled,
    eagleEyeEnabled: json.eagle_eye_enabled,
    ringEnabled: json.ring_enabled,
    storeUserCodes: json.store_user_codes,
    doorAccessLevel:
      json.door_access_level === "denials_only"
        ? DENIAL_ONLY
        : json.door_access_level === "all"
        ? ALL
        : NONE,
  });

/**
 * @param { import("./__generated__/controlSystemServicesModel_servicesManager.graphql").controlSystemServicesModel_servicesManager$key } key
 * @returns {ReturnType<createControlSystemServices>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment controlSystemServicesModel_servicesManager on ServicesManager
      @inline {
        armingAppEnabled
        doorsEditingEnabled
        favoriteEditingEnabled
        fullAppEnabled
        lightControlEnabled
        lockControlEnabled
        scheduleEditEnabled
        thermostatControlEnabled
        trafficCountEnabled
        userCodeEditingEnabled
        zwaveNodeEditingEnabled
        sensorActivityEnabled
        realTimeEventsEnabled
        outputOptionsEditingEnabled
        dmpVideoEnabled
        varEnabled
        iensoEnabled
        hikvisionNvrEnabled
        openEyeEnabled
        eagleEyeEnabled
        ringDoorbellEnabled
        storeUserCodes
        doorAccessLevel
      }
    `,
    key
  );
  return createControlSystemServices({
    armingAppEnabled: data.armingAppEnabled,
    doorsEditingEnabled: data.doorsEditingEnabled,
    favoriteEditingEnabled: data.favoriteEditingEnabled,
    fullAppEnabled: data.fullAppEnabled,
    lightControlEnabled: data.lightControlEnabled,
    lockControlEnabled: data.lockControlEnabled,
    scheduleEditEnabled: data.scheduleEditEnabled,
    thermostatControlEnabled: data.thermostatControlEnabled,
    trafficCountEnabled: data.trafficCountEnabled,
    userCodeEditingEnabled: data.userCodeEditingEnabled,
    zwaveNodeEditingEnabled: data.zwaveNodeEditingEnabled,
    sensorActivityEnabled: data.sensorActivityEnabled,
    realTimeEventsEnabled: data.realTimeEventsEnabled,
    outputOptionsEditingEnabled: data.outputOptionsEditingEnabled,
    dmpVideoEnabled: data.dmpVideoEnabled,
    varEnabled: data.varEnabled,
    iensoEnabled: data.iensoEnabled,
    hikvisionNvrEnabled: data.hikvisionNvrEnabled,
    openEyeEnabled: data.openEyeEnabled,
    eagleEyeEnabled: data.eagleEyeEnabled,
    ringEnabled: data.ringDoorbellEnabled,
    storeUserCodes: data.storeUserCodes,
    doorAccessLevel: data.doorAccessLevel,
  });
};
