/**
 *
 * UsersPage Messages
 * @author Matt Shaffer, Chad Watson
 *
 * This contains all the text for the UsersPage component.
 *
 */
import { getEntries } from "common/utils/universal/object";
import { useIntl } from "hooks/useIntl";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "app.pages.UsersPage.title",
    defaultMessage: "Users",
  },
  managedInAdminSuggestion: {
    id: "app.pages.UsersPage.managedInAdminSuggestion",
    defaultMessage:
      "This user is currently being managed in the Admin section.",
  },
  goToAdminButton: {
    id: "app.pages.UsersPage.goToAdminButton",
    defaultMessage: "Go to Admin",
  },
  numberTaken: {
    id: "app.pages.UsersPage.numberTaken",
    defaultMessage: "This number is already taken.",
  },
  numberNotInRange: {
    id: "app.pages.UsersPage.numberNotInRange",
    defaultMessage: "Must be in {min} - {max}.",
  },
  expirationFieldLabel: {
    id: "app.pages.UsersPage.expirationFieldLabel",
    defaultMessage: "Expiration Date",
  },
  expirationOneHourOptionLabel: {
    id: "app.pages.UsersPage.expirationOneHourOptionLabel",
    defaultMessage: "1 hr",
  },
  expirationOneDayOptionLabel: {
    id: "app.pages.UsersPage.expirationOneDayOptionLabel",
    defaultMessage: "1 day",
  },
  expirationOneWeekOptionLabel: {
    id: "app.pages.UsersPage.expirationOneWeekOptionLabel",
    defaultMessage: "1 week",
  },
  expirationCustomOptionLabel: {
    id: "app.pages.UsersPage.expirationCustomOptionLabel",
    defaultMessage: "Custom",
  },
  permissionsHelpText: {
    id: "app.pages.UsersPage.permissionsHelpText",
    defaultMessage: "Description...",
  },
  confirmDiscard: {
    id: "app.pages.UsersPage.confirmDiscard",
    defaultMessage: "Are you sure you want to discard your changes?",
  },
  confirmDelete: {
    id: "app.pages.UsersPage.confirmDelete",
    defaultMessage:
      "Are you sure you want to delete this user? All user codes and credentials will be deleted from your system.",
  },
  new: {
    id: "app.pages.UsersPage.new",
    defaultMessage: "New",
  },
  newUser: {
    id: "app.pages.UsersPage.newUser",
    defaultMessage: "New User",
  },
  user: {
    id: "app.pages.UsersPage.user",
    defaultMessage: "User",
  },
  firstName: {
    id: "app.pages.UsersPage.firstName",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "app.pages.UsersPage.lastName",
    defaultMessage: "Last Name",
  },
  globalProfilesToggleMessage: {
    id: "app.pages.UsersPage.globalProfilesToggleMessage",
    defaultMessage: "Apply the same profiles to all user codes and credentials",
  },
  firstNameMissing: {
    id: "app.components.Form.firstNameMissing",
    defaultMessage: "First name can't be blank",
  },
  lastNameMissing: {
    id: "app.components.Form.lastNameMissing",
    defaultMessage: "Last name can't be blank",
  },
  globalProfilesMissing: {
    id: "app.components.Form.globalProfilesMissing",
    defaultMessage: "At least one profile must be selected",
  },
  globalPermissionsMissing: {
    id: "app.components.Form.globalPermissionsMissing",
    defaultMessage: "An authority must be selected",
  },
  profiles: {
    id: "app.pages.UsersPage.profiles",
    defaultMessage: "Profiles",
  },
  accessibleSystems: {
    id: "app.pages.UsersPage.accessibleSystems",
    defaultMessage: "Accessible Systems",
  },
  expirationDate: {
    id: "app.pages.UsersPage.expirationDate",
    defaultMessage: "Expiration Date",
  },
  expirationDateMissing: {
    id: "app.pages.UsersPage.expirationMissing",
    defaultMessage: "Expiration date is required",
  },
  expirationDateInvalid: {
    id: "app.pages.UsersPage.expirationInvalid",
    defaultMessage: "Expiration date is invalid",
  },
  showUserCodes: {
    id: "app.pages.UsersPage.showUserCodes",
    defaultMessage: "Show User Codes",
  },
  hideUserCodes: {
    id: "app.pages.UsersPage.hideUserCodes",
    defaultMessage: "Hide User Codes",
  },
  back: {
    id: "app.pages.BulkImport.back",
    defaultMessage: "Back",
  },
  CSV: {
    id: "app.pages.BulkImport.CSV",
    defaultMessage: "CSV",
  },
  csvDescription: {
    id: "app.pages.BulkImport.csvDescription",
    defaultMessage:
      "Use the CSV template below to add user codes in bulk to this system.",
  },
  loading: {
    id: "app.pages.BulkImport.loading",
    defaultMessage: "Loading...",
  },
  selectCSV: {
    id: "app.pages.BulkImport.selectCSV",
    defaultMessage: "Select CSV File",
  },
  dropCSV: {
    id: "app.pages.BulkImport.dropCSV",
    defaultMessage: "Drop CSV File Here",
  },
  createError: {
    id: "app.pages.BulkImport.unknownError",
    defaultMessage:
      "An error occurred while uploading the user codes, please try again.",
  },
  pollError: {
    id: "app.pages.BulkImport.pollError",
    defaultMessage:
      "An error occurred while processing the user codes, please try again.",
  },
  unknownError: {
    id: "app.pages.BulkImport.unknownError",
    defaultMessage:
      "An error occurred while uploading the user codes, please try again.",
  },
  upload: {
    id: "app.pages.BulkImport.upload",
    defaultMessage: "Upload",
  },
  retry: {
    id: "app.pages.BulkImport.retry",
    defaultMessage: "Retry",
  },
  downloadTemplate: {
    id: "app.pages.BulkImport.downloadTemplate",
    defaultMessage: "Download the CSV Template",
  },
  success: {
    id: "app.pages.BulkImport.success",
    defaultMessage: "Import Complete!",
  },
  results: {
    id: "app.pages.BulkImport.results",
    defaultMessage: `Import completed for {name}.`,
  },
  resultMessage: {
    id: "app.pages.BulkImport.resultsMessage",
    defaultMessage: `{amount} out of {total} user codes added.`,
  },
  badCodes: {
    id: "app.pages.BulkImport.badCodes",
    defaultMessage:
      "{amount} {amount, plural, one {user code} other {user codes}} failed to save.",
  },
  processing: {
    id: "app.pages.BulkImport.processing",
    defaultMessage: "Processing...",
  },
  errorOccurred: {
    id: "app.pages.BulkImport.errorOccurred",
    defaultMessage: "Error",
  },
  uploadAnother: {
    id: "app.pages.BulkImport.uploadAnother",
    defaultMessage: "Import Another File",
  },
  userName: {
    id: "app.pages.BulkImport.userName",
    defaultMessage: "CSV Import",
  },
  addingUserCodes: {
    id: "app.pages.BulkImport.addingUserCodes",
    defaultMessage: "Adding user codes to {system}...",
  },
  bulkImport: {
    id: "app.pages.UsersPage.bulkImport",
    defaultMessage: "CSV Import",
  },
  notAvailable: {
    id: "app.pages.UsersPage.notAvailable",
    defaultMessage: "N/A",
  },
  existingUserFormTitle: {
    id: "app.pages.UsersPage.existingUserFormTitle",
    defaultMessage: "Edit {name}",
  },
  newUserFormTitle: {
    id: "app.pages.UsersPage.newUserFormTitle",
    defaultMessage: "New User",
  },
  notEnoughMobileCredentials: {
    id: "app.pages.UsersPage.notEnoughMobileCredentials",
    defaultMessage:
      "You are out of available mobile credentials. Please contact your alarm company to purchase more.",
  },
  noUsers: {
    id: "app.components.UsersPage.noUsers",
    defaultMessage: "You do not have any users.",
  },
  apiErrorMessage: {
    id: "app.components.UsersPage.apiErrorMessage",
    defaultMessage: "{errorMessage}",
  },
  cancel: {
    id: "app.components.UsersPage.cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "app.components.UsersPage.confirm",
    defaultMessage: "Confirm",
  },
});

export default messages;

export const useMessages = () => {
  const { formatMessage } = useIntl();

  return getEntries(messages).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: (...args: any[]) => formatMessage(value, ...args),
    }),
    {} as { [key in keyof typeof messages]: (...args: any[]) => string }
  );
};
