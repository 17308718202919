/**
 * @generated SignedSource<<93b0fafeb349abda050b96f65a861cf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DoorStatus = "LOCKED" | "UNKNOWN" | "UNLOCKED";
import { FragmentRefs } from "relay-runtime";
export type doorModel_controlSystemDoorsConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly isPrivate: boolean;
      readonly isPublic: boolean;
      readonly name: string;
      readonly number: number;
      readonly override: boolean;
      readonly realTimeStatus: boolean;
      readonly status: DoorStatus;
      readonly tracked: boolean;
    } | null;
  }>;
  readonly " $fragmentType": "doorModel_controlSystemDoorsConnection";
};
export type doorModel_controlSystemDoorsConnection$key = {
  readonly " $data"?: doorModel_controlSystemDoorsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"doorModel_controlSystemDoorsConnection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "doorModel_controlSystemDoorsConnection"
};

(node as any).hash = "0f3692043b1d5fc252832ba8c1e1eff2";

export default node;
