/**
 * @generated SignedSource<<581f5489055fc01dff3ffa332c052370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dealerModel_customer$data = {
  readonly dealer: {
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly country: string;
    readonly email: string;
    readonly facebook: string;
    readonly id: string;
    readonly name: string;
    readonly phone1: string;
    readonly phone2: string;
    readonly postalCode: string;
    readonly state: string;
    readonly storeUserCodes: boolean;
    readonly twitter: string;
    readonly websiteLogoUrl: string;
  };
  readonly id: string;
  readonly " $fragmentType": "dealerModel_customer";
};
export type dealerModel_customer$key = {
  readonly " $data"?: dealerModel_customer$data;
  readonly " $fragmentSpreads": FragmentRefs<"dealerModel_customer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "dealerModel_customer"
};

(node as any).hash = "4740d497fc87707eeae54d05ef8bf42f";

export default node;
