/**
 *
 * UsersPage constants
 * @author Chad Watson
 *
 */

import { Map } from "immutable";
import { CODE_TYPES } from "models/systemUser";
import messages from "./messages";

export const NEW_LIST_ITEM_KEY = "NEW_USER";
export const NEW_USER_LIST_ITEM = Map({
  firstName: messages.new,
  lastName: messages.user
});

export const REQUEST_DATA = "app/UsersPage/REQUEST_DATA";
export const REFRESH_DATA = "app/UsersPage/REFRESH_DATA";
export const USER_SAVED = "app/UsersPage/USER_SAVED";
export const USER_DELETED = "app/UsersPage/USER_DELETED";
export const RESET_SAVE_STATUS = "app/UsersPage/RESET_SAVE_STATUS";
export const ASSOCIATE_CODE_WITH_USER =
  "app/UsersPage/ASSOCIATE_CODE_WITH_USER";
export const RESET_ORPHANED_CODES_ASSOCIATED_TO_USERS_BEFORE_SAVE =
  "app/UsersPage/RESET_ORPHANED_CODES_ASSOCIATED_TO_USERS_BEFORE_SAVE";
export const EDITING_CANCELLED = "app/UsersPage/EDITING_CANCELLED";
export const SELECT_LIST_ITEM = "app/UsersPage/SELECT_LIST_ITEM";
export const DESELECT_LIST_ITEM = "app/UsersPage/DESELECT_LIST_ITEM";
export const CLEAR_CODE_AND_USER_CODE_ERROR_MESSAGE =
  "app/UsersPage/CLEAR_CODE_AND_USER_CODE_ERROR_MESSAGE";

export const DEFAULT_CODE_TYPE = CODE_TYPES.PIN;
