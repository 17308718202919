/**
 *
 * Confirm Model
 * @author Chad Watson
 *
 *
 */
import { Record } from "immutable";
import { noop } from "utils";
export const createConfirm = Record({
  message: "",
  onConfirm: noop,
  onCancel: noop,
});
