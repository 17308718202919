import Maybe from "data.maybe";
import { OrderedMap, OrderedSet, Record, Seq, Set } from "immutable";
import {
  __,
  applySpec,
  compose,
  concat,
  identity,
  inc,
  invertObj,
  isNil,
  map,
  omit,
  prop,
  range,
  toString,
} from "ramda";
import { getOrElse, prune, safeArrayOfInts, safeToInt, toInt } from "utils";

export const createProfile = Record({
  number: 0,
  name: "",
  totalUsercodesAssigned: 0,
  updatedName: null,
  updatedNumber: null,
  arm: false,
  disarm: false,
  alarmSilence: false,
  sensorReset: false,
  lockdown: false,
  lockdownOverride: false,
  doorLockUnlock: false,
  doorAccess: false,
  armedAreas: false,
  outputsOnOff: false,
  zoneStatus: false,
  bypassZones: false,
  zoneMonitor: false,
  systemStatus: false,
  systemTest: false,
  profiles: false,
  userCodes: false,
  extend: false,
  schedules: false,
  setTime: false,
  displayEvents: false,
  serviceRequest: false,
  fireDrill: false,
  tempCode: false,
  antiPassback: false,
  easyArmDisarm: false,
  useSecondaryLanguage: false,
  cardPlusPin: false,
  wifiSetup: false,
  rearmDelay: 0,
  outputGroup: 0,
  accessibleAreas: Set(),
  armableAreas: Set(),
  accessibleSchedules: Set(),
  privateDoors: Set(),
  saving: false,
  deleting: false,
  errors: null,
  linkedProfile: null,
  inactiveUserAuditDays: 0,
});

const KEYS_MAP = Object.freeze({
  number: "number",
  name: "name",
  rearm_dly: "rearmDelay",
  out_grp: "outputGroup",
  accessible_areas: "accessibleAreas",
  armable_areas: "armableAreas",
  accessible_schedules: "accessibleSchedules",
  private_doors: "privateDoors",
  arm: "arm",
  disarm: "disarm",
  alrm_silnc: "alarmSilence",
  snsr_reset: "sensorReset",
  lckdwn: "lockdown",
  lkdn_ovrrd: "lockdownOverride",
  d_lck_ulck: "doorLockUnlock",
  door_accss: "doorAccess",
  arm_areas: "armedAreas",
  outputs_on: "outputsOnOff",
  zone_stat: "zoneStatus",
  bypass: "bypassZones",
  zone_monit: "zoneMonitor",
  systm_stat: "systemStatus",
  systm_test: "systemTest",
  profiles: "profiles",
  user_codes: "userCodes",
  xtend: "extend",
  scheds: "schedules",
  set_time: "setTime",
  disp_event: "displayEvents",
  srvc_req: "serviceRequest",
  fire_drill: "fireDrill",
  temp_code: "tempCode",
  anti_pass: "antiPassback",
  easy_arm: "easyArmDisarm",
  sec_lang: "useSecondaryLanguage",
  card_p_pin: "cardPlusPin",
  wifi_setup: "wifiSetup",
  linked_profile_id: "linkedProfile",
  inactivuad: "inactiveUserAuditDays",
});

//Keys that you want included in profiles reports but not on the profiles page form
//If you get an error for missing ids from react-intl, it's because there's a key in the KEYS_MAP that isn't in the profiles form
const BOOLEAN_KEYS_MAP = omit(
  [
    "name",
    "number",
    "out_grp",
    "accessible_areas",
    "accessible_schedules",
    "armable_areas",
    "private_doors",
    "rearm_dly",
    "linked_profile_id",
    "inactivuad",
  ],
  KEYS_MAP
);

export const BOOLEAN_ATTRIBUTES = Object.values(BOOLEAN_KEYS_MAP);

export const NUMBER_RANGE = {
  MIN: 1,
  MAX: 99,
};

const toIntOrZero = (x) => safeToInt(x).getOrElse(0);
const safeSetOfInts = compose(Set, safeArrayOfInts);

export const createProfileFromJson = (json) => {
  return createProfile(
    Seq({
      ...json,
      number: toIntOrZero(json.number),
      name: json.name || "",
      rearm_dly: toIntOrZero(json.rearm_dly),
      out_grp: toIntOrZero(json.out_grp),
      accessible_areas: safeSetOfInts(json.accessible_areas),
      armable_areas: safeSetOfInts(json.armable_areas),
      accessible_schedules: safeSetOfInts(json.accessible_schedules),
      private_doors: safeSetOfInts(json.private_doors),
      inactivuad: toIntOrZero(json.inactivuad),
    })
      .mapKeys(prop(__, KEYS_MAP))
      .toObject()
  );
};

export const createProfilesFromJson = (json) =>
  Seq(json)
    .map(compose(createProfileFromJson, prop("profile")))
    .reduce((acc, profile) => acc.set(profile.number, profile), OrderedMap());

export const apiRequestObject = (profile) => ({
  number: profile.number,
  name: profile.name,
  rearm_dly: toInt(profile.rearmDelay),
  out_grp: toInt(profile.outputGroup),
  accessible_areas: profile.accessibleAreas.toArray(),
  armable_areas: profile.armableAreas.toArray(),
  accessible_schedules: profile.accessibleSchedules.toArray(),
  private_doors: profile.privateDoors.toArray(),
  arm: profile.arm,
  disarm: profile.disarm,
  alrm_silnc: profile.alarmSilence,
  snsr_reset: profile.sensorReset,
  lckdwn: profile.lockdown,
  lkdn_ovrrd: profile.lockdownOverride,
  d_lck_ulck: profile.doorLockUnlock,
  door_accss: profile.doorAccess,
  arm_areas: profile.armedAreas,
  outputs_on: profile.outputsOnOff,
  zone_stat: profile.zoneStatus,
  bypass: profile.bypassZones,
  zone_monit: profile.zoneMonitor,
  systm_stat: profile.systemStatus,
  systm_test: profile.systemTest,
  profiles: profile.profiles,
  user_codes: profile.userCodes,
  xtend: profile.extend,
  scheds: profile.schedules,
  set_time: profile.setTime,
  disp_event: profile.displayEvents,
  srvc_req: profile.serviceRequest,
  fire_drill: profile.fireDrill,
  temp_code: profile.tempCode,
  anti_pass: profile.antiPassback,
  easy_arm: profile.easyArmDisarm,
  sec_lang: profile.useSecondaryLanguage,
  card_p_pin: profile.cardPlusPin,
  wifi_setup: profile.wifiSetup,
  inactivuad: toInt(profile.inactiveUserAuditDays),
});

/**
 * Builds an error model for errors in response to CRUD requests
 * @param  {object} errors hash of errors from the response
 * @return {object} hash of profile properties ready for an API request
 */
export const crudError = compose(
  prune,
  applySpec({
    ...map(prop, invertObj(KEYS_MAP)),
    base: prop("base"),
  })
);

export const availableNumbers = (profiles) => {
  const takenProfileNumbers = profiles.keySeq().toSet();
  return OrderedSet(range(NUMBER_RANGE.MIN, inc(NUMBER_RANGE.MAX))).filterNot(
    (number) => takenProfileNumbers.has(number)
  ); // Using filterNot to overcome an apparent bug in subtract()
};

const firstAvailableNumber = (profiles) =>
  Maybe.fromNullable(availableNumbers(profiles).first());

export const newProfile = compose(
  compose(
    createProfile,
    applySpec({
      name: compose(concat("PROFILE "), toString),
      number: identity,
    })
  ),
  getOrElse(NUMBER_RANGE.MAX),
  firstAvailableNumber
);

export const hasLinkedProfile = (profile) => !isNil(profile.linkedProfile);
