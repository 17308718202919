/**
 *
 * Z-Wave Node Model
 * @author Chad Watson
 *
 *
 */
import { reduce } from "ramda";
import { OrderedMap } from "immutable";
import { toInt } from "utils";
import createLightFromJson from "models/light";
import createLockFromJson from "models/lock";
import createThermostatFromJson from "models/thermostat";
import createBarrierOperatorFromJson from "models/barrierOperator";
const initialParseNodes = {
  lights: OrderedMap(),
  locks: OrderedMap(),
  thermostats: OrderedMap(),
  barrierOperators: OrderedMap()
};

const reducer = (parsedNodes = initialParseNodes, node) => {
  switch (node.node_type.toUpperCase()) {
    case "LIGHT":
      return {
        ...parsedNodes,
        lights: parsedNodes.lights.set(
          toInt(node.number),
          createLightFromJson(node)
        )
      };

    case "LOCK":
      return {
        ...parsedNodes,
        locks: parsedNodes.locks.set(
          toInt(node.number),
          createLockFromJson(node)
        )
      };

    case "THERMOSTAT":
      return {
        ...parsedNodes,
        thermostats: parsedNodes.thermostats.set(
          toInt(node.number),
          createThermostatFromJson(node)
        )
      };

    case "BARRIER_OPERATOR":
      return {
        ...parsedNodes,
        barrierOperators: parsedNodes.barrierOperators.set(
          toInt(node.number),
          createBarrierOperatorFromJson(node)
        )
      };

    default:
      return parsedNodes;
  }
};

export const parseNodes = reduce(reducer, initialParseNodes);
