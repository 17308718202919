/**
 *
 * UsersPage reducer
 * @author Chad Watson
 *
 */

import { Map, Set } from "immutable";
import { compose, defaultTo, always } from "ramda";
import {
  immutableSet,
  immutableWithMutations,
  immutableUpdate,
  immutableAdd,
} from "utils";
import { reducerFactory } from "utils/reducers";
import { STATUSES } from "components/Form";
import { createUserListItemKey } from "store/systems/selectors/users";
import { LOG_OUT_BUTTON_PRESSED } from "store/auth/constants";
import { USER_CODE_SUBMITTED } from "store/systems/constants";
import {
  SAVE_USER,
  DELETE_USER,
  USER_SAVED,
  USER_SAVED_WITH_CODE_ERRORS,
  USER_DELETED,
} from "store/systems/constants/users";
import {
  RESET_SAVE_STATUS,
  RESET_ORPHANED_CODES_ASSOCIATED_TO_USERS_BEFORE_SAVE,
  SELECT_LIST_ITEM,
  DESELECT_LIST_ITEM,
  EDITING_CANCELLED,
  ASSOCIATE_CODE_WITH_USER,
} from "./constants";

export const initialState = Map({
  saveStatus: STATUSES.SAVE,
  orphanedCodesAssociatedToUsersBeforeSave: Map(),
  selectedListItemKey: null,
});

const actionHandlers = {
  [SAVE_USER]: immutableSet("saveStatus", STATUSES.SAVING),
  [USER_SAVED]: (state, action) =>
    state.withMutations(
      immutableWithMutations((mutableState) =>
        mutableState
          .set(
            "selectedListItemKey",
            createUserListItemKey(action.user.get("id").toString())
          )
          .set("saveStatus", STATUSES.SAVED)
          .set(
            "orphanedCodesAssociatedToUsersBeforeSave",
            initialState.get("orphanedCodesAssociatedToUsersBeforeSave")
          )
      )
    ),
  [USER_SAVED_WITH_CODE_ERRORS]: (state, action) =>
    state.withMutations(
      immutableWithMutations((mutableState) =>
        mutableState
          .set(
            "selectedListItemKey",
            createUserListItemKey(action.user.get("id").toString())
          )
          .set("saveStatus", STATUSES.SAVED)
      )
    ),
  [DELETE_USER]: immutableSet("saveStatus", STATUSES.DELETING),
  [USER_DELETED]: immutableWithMutations(
    compose(
      immutableSet("selectedListItemKey", null),
      immutableSet("saveStatus", STATUSES.SAVE),
      immutableSet(
        "orphanedCodesAssociatedToUsersBeforeSave",
        initialState.get("orphanedCodesAssociatedToUsersBeforeSave")
      )
    )
  ),
  [RESET_SAVE_STATUS]: immutableSet("saveStatus", STATUSES.SAVE),
  [ASSOCIATE_CODE_WITH_USER]: (state, { userId, codeId }) =>
    state.update(
      "orphanedCodesAssociatedToUsersBeforeSave",
      immutableUpdate(userId, compose(immutableAdd(codeId), defaultTo(Set())))
    ),
  [RESET_ORPHANED_CODES_ASSOCIATED_TO_USERS_BEFORE_SAVE]: (state) =>
    state.set(
      "orphanedCodesAssociatedToUsersBeforeSave",
      initialState.get("orphanedCodesAssociatedToUsersBeforeSave")
    ),
  [SELECT_LIST_ITEM]: (state, action) =>
    state.set("selectedListItemKey", action.key),
  [DESELECT_LIST_ITEM]: immutableWithMutations(
    compose(
      immutableSet("saveStatus", STATUSES.SAVE),
      immutableSet("selectedListItemKey", null)
    )
  ),
  [EDITING_CANCELLED]: immutableWithMutations(
    compose(
      immutableSet("saveStatus", STATUSES.SAVE),
      immutableSet("selectedListItemKey", null)
    )
  ),
  [USER_CODE_SUBMITTED]: always(initialState),
  [LOG_OUT_BUTTON_PRESSED]: always(initialState),
};

export default reducerFactory(initialState, actionHandlers);
