/**
 * @generated SignedSource<<76aed432e2aea5ef544b9fee5521228c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrackedOutputModel_controlSystemOutputsConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly action: string | null;
      readonly id: string;
      readonly name: string;
      readonly number: number;
    };
  }>;
  readonly " $fragmentType": "TrackedOutputModel_controlSystemOutputsConnection";
};
export type TrackedOutputModel_controlSystemOutputsConnection$key = {
  readonly " $data"?: TrackedOutputModel_controlSystemOutputsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrackedOutputModel_controlSystemOutputsConnection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TrackedOutputModel_controlSystemOutputsConnection"
};

(node as any).hash = "4b27798eab47f9ac926326ce888508db";

export default node;
