/**
 *
 * Lock Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { prop, when, always, compose, ascend, ifElse, isNil } from "ramda";
import { Record, OrderedMap, Seq } from "immutable";
import { toInt } from "utils";
import { DOOR_TYPES } from "constants/index";
import zwaveDevicePropsFromJson, { defaultZwaveDeviceProps } from "./zwave";
export const STATUSES = {
  SECURED: "SECURED",
  UNSECURED: "UNSECURED",
  UNKNOWN: "UNKNOWN"
};
const createLock = Record({
  ...defaultZwaveDeviceProps,
  nodeType: "LOCK",
  status: STATUSES.UNKNOWN,
  doorType: DOOR_TYPES.LOCK,
  locked: false,
  batteryPercent: null
});
const batteryPercentFromJson = compose(
  ifElse(
    isNil,
    always(null),
    compose(
      when(isNaN, always(null)),
      toInt
    )
  ),
  prop("battery_percent")
);
export const createLockFromJson = json =>
  createLock({
    ...zwaveDevicePropsFromJson(json),
    status: json.status,
    batteryPercent: batteryPercentFromJson(json),
    locked: json.status === STATUSES.SECURED
  });
export default createLockFromJson;
export const createLocksFromJson = json =>
  Seq(json)
    .map(createLockFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, lock) => acc.set(lock.number, lock), OrderedMap());
