/**
 *
 * systemGroup models
 * @author Chad Watson
 *
 *
 */
import { compose, applySpec, prop, defaultTo } from "ramda";
import { List, Record } from "immutable";
import { prune } from "utils";
export const createSystemGroup = Record({
  id: 0,
  name: "",
  controlSystemIds: List()
});
export default compose(
  prune,
  applySpec({
    id: prop("id"),
    name: compose(
      defaultTo(""),
      prop("name")
    ),
    controlSystemIds: compose(
      defaultTo([]),
      prop("control_system_ids")
    )
  })
);
const systemGroupApiObject = applySpec({
  id: prop("id"),
  name: prop("name"),
  control_system_ids: prop("controlSystemIds")
});
export const apiRequestParams = applySpec({
  id: prop("id"),
  control_system_group: systemGroupApiObject
});
