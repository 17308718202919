/**
 * @generated SignedSource<<640c50eb68d8eee9aa8c324f8726cd46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type UserRole = "ACCESS_ONLY" | "ADMIN" | "STANDARD";
import { FragmentRefs } from "relay-runtime";
export type userModel_customerAppUser$data = {
  readonly authToken: string | null;
  readonly customer: {
    readonly dealer: {
      readonly id: string;
    };
    readonly id: string;
    readonly name: string;
  };
  readonly email: string;
  readonly emailClips: boolean;
  readonly firstName: string;
  readonly hasPermissionsForAllPanels: boolean;
  readonly hidden: boolean;
  readonly id: string;
  readonly lastName: string;
  readonly lastSignInAt: string | null;
  readonly lockedAt: string | null;
  readonly person: {
    readonly id: string;
  };
  readonly role: UserRole;
  readonly userPermissions: ReadonlyArray<{
    readonly armingEnabled: boolean;
    readonly controlSystem: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly multiPanelProfilesEnabled: boolean;
    readonly multiPanelSchedulesEnabled: boolean;
    readonly multiPanelUserCodesEnabled: boolean;
    readonly remotePanicEmergencyEnabled: boolean;
    readonly remotePanicFireEnabled: boolean;
    readonly remotePanicPoliceEnabled: boolean;
    readonly reportsEnabled: boolean;
    readonly viewStoredUserCodes: boolean;
  }>;
  readonly " $fragmentType": "userModel_customerAppUser";
};
export type userModel_customerAppUser$key = {
  readonly " $data"?: userModel_customerAppUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"userModel_customerAppUser">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userModel_customerAppUser"
};

(node as any).hash = "aab45972377c1d9dcc071544ea7133e2";

export default node;
