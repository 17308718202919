/**
 *
 * TimeZones model
 * @author Chad Watson
 *
 *
 */
import { compose, map, prop, ascend, sort, identity } from "ramda";
import { List } from "immutable";
export const createTimeZonesFromJson = compose(
  List,
  sort(ascend(identity)),
  map(prop("name"))
);
