/**
 *
 * Payment models
 * @author Chad Watson
 *
 */

import { prop, applySpec } from "ramda";
import { toKeyed } from "utils";

export const stripeKey = prop("stripe_key");

const card = applySpec({
  id: prop("id"),
  brand: prop("brand"),
  expMonth: prop("exp_month"),
  expYear: prop("exp_year"),
  default: prop("default"),
  logo: prop("logo"),
  last4: prop("last4")
});

export const cards = toKeyed(prop("id"), card);
