import moment from "moment";
import Maybe from "data.maybe";
import { Seq, Record, Map } from "immutable";
import graphql from "babel-plugin-relay/macro";
import {
  compose,
  applySpec,
  prop,
  map,
  when,
  isNil,
  complement,
  defaultTo,
  always,
  values,
  unless,
  toString,
  is,
} from "ramda";
import { toKeyed, toInt } from "utils";
import { readInlineData } from "react-relay";
import {
  asID,
  fromControlSystemId,
  fromCustomerId,
  fromCustomerPersonId,
  fromCustomerUserId,
  fromDealerId,
  fromUserPermissionsId,
} from "securecom-graphql/client";

export const createAppUserPermissionsFromJson = (json) =>
  createPanelPermissions({
    id: json.id,
    panelId: json.panel_id,
    reportsEnabled: json.reports_enabled,
    multiPanelSchedulesEnabled: json.multi_panel_schedules_enabled,
    multiPanelUserCodesEnabled: json.multi_panel_user_codes_enabled,
    multiPanelProfilesEnabled: json.multi_panel_profiles_enabled,
    viewStoredUserCodes: json.view_stored_user_codes,
    armingEnabled: json.arming_enabled,
    remotePanicEmergencyEnabled: json.remote_panic_emergency_enabled,
    remotePanicFireEnabled: json.remote_panic_fire_enabled,
    remotePanicPoliceEnabled: json.remote_panic_police_enabled,
  });
export const createAppUser = Record({
  id: 0,
  email: "",
  role: "",
  firstName: "",
  lastName: "",
  personId: "",
  accessibleId: "",
  accessibleType: "",
  customerName: "",
  imageUrl: null,
  userPermissions: Map(),
  /** @type Moment | null */
  lastSignInAt: null,
  /** @type Moment | null */
  lockedAt: null,
  emailClips: false,
  hidden: false,
  visibleLogin: "",
  authToken: "",
  dealerTempExpires: Maybe.Nothing(),
  userPermissionsAll: false,
  dealerId: 0,
});
export const createPanelPermissions = Record({
  id: 0,
  panelId: 0,
  multiPanelUserCodesEnabled: false,
  multiPanelSchedulesEnabled: false,
  multiPanelProfilesEnabled: false,
  reportsEnabled: false,
  viewStoredUserCodes: false,
  armingEnabled: false,
  remotePanicEmergencyEnabled: false,
  remotePanicFireEnabled: false,
  remotePanicPoliceEnabled: false,
  csiContactManagementEnabled: false,
  csiSystemTestsEnabled: false,
});
export const createAppUserFromJson = (json) =>
  createAppUser({
    id: json.id,
    email: json.email,
    role: json.role || "",
    firstName: json.first_name,
    lastName: json.last_name,
    personId: Maybe.fromNullable(json.person_id).map(toString).getOrElse(""),
    accessibleId: json.accessible_id.toString(),
    accessibleType: json.accessible_type,
    imageUrl: json.user_image_url,
    userPermissions: Seq(json.user_permissions)
      .map(createAppUserPermissionsFromJson)
      .reduce((acc, item) => acc.set(item.panelId.toString(), item), Map()),
    lastSignInAt: Maybe.fromNullable(json.last_sign_in_at)
      .map(moment)
      .getOrElse(null),
    lockedAt: Maybe.fromNullable(json.locked_at).map(moment).getOrElse(null),
    emailClips: Boolean(json.email_clips),
    hidden: json.hidden,
    dealerTempExpires: Maybe.fromNullable(json.dealer_temp_expires).map(
      (dateString) => new Date(dateString)
    ),
    userPermissionsAll: Maybe.fromNullable(json.user_permissions_all),
  });
export const ADMIN_USER_ROLE = "admin";
const userPermissions = applySpec({
  id: prop("id"),
  panelId: prop("panel_id"),
  userId: prop("user_id"),
  destroy: always(false),
  reportsEnabled: prop("reports_enabled"),
  multiPanelSchedulesEnabled: prop("multi_panel_schedules_enabled"),
  multiPanelUserCodesEnabled: prop("multi_panel_user_codes_enabled"),
  multiPanelProfilesEnabled: prop("multi_panel_profiles_enabled"),
  viewStoredUserCodes: prop("view_stored_user_codes"),
  armingEnabled: prop("arming_enabled"),
  remotePanicEmergencyEnabled: prop("remote_panic_emergency_enabled"),
  remotePanicFireEnabled: prop("remote_panic_fire_enabled"),
  remotePanicPoliceEnabled: prop("remote_panic_police_enabled"),
});
export const newUserPermission = applySpec({
  panelId: compose(unless(is(String), toString), prop("panelId")),
  reportsEnabled: compose(defaultTo(false), prop("reports_enabled")),
  multiPanelSchedulesEnabled: compose(
    defaultTo(false),
    prop("multi_panel_schedules_enabled")
  ),
  multiPanelUserCodesEnabled: compose(
    defaultTo(false),
    prop("multi_panel_user_codes_enabled")
  ),
  multiPanelProfilesEnabled: compose(
    defaultTo(false),
    prop("multi_panel_profiles_enabled")
  ),
  armingEnabled: compose(defaultTo(false), prop("arming_enabled")),
  viewStoredUserCodes: compose(
    defaultTo(false),
    prop("view_stored_user_codes")
  ),
});
export const normalizeAccessibleId = compose(
  unless(is(String), toString),
  prop("accessible_id")
);
/**
 * Builds an user model
 * @param  {object} about me API JSON response for a user
 * @return {object}
 */

export default applySpec({
  id: prop("id"),
  email: prop("email"),
  role: prop("role"),
  firstName: prop("first_name"),
  lastName: prop("last_name"),
  personId: compose(unless(isNil, toString), prop("person_id")),
  accessibleId: normalizeAccessibleId,
  accessibleType: prop("accessible_type"),
  imageUrl: prop("user_image_url"),
  userPermissions: compose(
    toKeyed(prop("panel_id"), userPermissions),
    prop("user_permissions")
  ),
  lastSignInAt: compose(
    when(complement(isNil), moment),
    prop("last_sign_in_at")
  ),
  lockedAt: compose(when(complement(isNil), moment), prop("locked_at")),
  emailClips: prop("email_clips"),
  hidden: prop("hidden"),
  visibleLogin: prop("visibleLogin"),
  userPermissionsAll: prop("user_permissions_all"),
});
export const newUser = compose(
  applySpec({
    email: compose(defaultTo(""), prop("email")),
    userPermissions: compose(defaultTo({}), prop("permissions")),
    personId: compose(defaultTo(null), prop("personId")),
  }),
  defaultTo({})
);
const apiPermissions = applySpec({
  id: compose(toInt, prop("id")),
  _destroy: prop("destroy"),
  panel_id: compose(toInt, prop("panelId")),
  reports_enabled: prop("reportsEnabled"),
  multi_panel_schedules_enabled: prop("multiPanelSchedulesEnabled"),
  multi_panel_user_codes_enabled: prop("multiPanelUserCodesEnabled"),
  multi_panel_profiles_enabled: prop("multiPanelProfilesEnabled"),
  view_stored_user_codes: prop("viewStoredUserCodes"),
  arming_enabled: prop("armingEnabled"),
  remote_panic_emergency_enabled: prop("remotePanicEmergencyEnabled"),
  remote_panic_fire_enabled: prop("remotePanicFireEnabled"),
  remote_panic_police_enabled: prop("remotePanicPoliceEnabled"),
});
export const buildRequest = applySpec({
  id: compose(when(isNil, always(undefined)), prop("id")),
  email: prop("email"),
  first_name: prop("firstName"),
  last_name: prop("lastName"),
  password: prop("password"),
  email_clips: prop("emailClips"),
  person_id: prop("personId"),
  role: prop("role"),
  user_permissions_attributes: compose(
    map(apiPermissions),
    values,
    prop("userPermissions")
  ),
});

/**
 * @param { import("./__generated__/userModel_customerAppUser.graphql").userModel_customerAppUser$key } key
 * @returns {ReturnType<createAppUser>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment userModel_customerAppUser on CustomerUser @inline {
        id
        email
        firstName
        lastName
        lastSignInAt
        lockedAt
        hidden
        role
        emailClips
        hasPermissionsForAllPanels
        authToken
        person {
          id
        }
        userPermissions {
          id
          controlSystem {
            id
          }
          multiPanelUserCodesEnabled
          multiPanelSchedulesEnabled
          reportsEnabled
          viewStoredUserCodes
          armingEnabled
          remotePanicPoliceEnabled
          remotePanicFireEnabled
          remotePanicEmergencyEnabled
          multiPanelProfilesEnabled
        }
        customer {
          id
          name
          dealer {
            id
          }
        }
      }
    `,
    key
  );

  return createAppUser({
    id: Number(fromCustomerUserId(asID(data.id)).userId),
    email: data.email,
    role: data.role === "ADMIN" ? "admin" : "",
    firstName: data.firstName,
    lastName: data.lastName,
    personId: fromCustomerPersonId(asID(data.person.id)).personId,
    accessibleId: fromCustomerId(asID(data.customer.id)).customerId,
    accessibleType: "Customer",
    authToken: data.authToken ?? "",
    customerName: data.customer.name,
    dealerId: Number(fromDealerId(data.customer.dealer.id).dealerId),
    lastSignInAt: data.lastSignInAt ? moment(data.lastSignInAt) : null,
    lockedAt: data.lockedAt ? moment(data.lockedAt) : null,
    emailClips: data.emailClips,
    hidden: data.hidden,
    userPermissionsAll: data.hasPermissionsForAllPanels,
    userPermissions: Seq(data.userPermissions)
      .map((userPermissions) =>
        createPanelPermissions({
          id: Number(fromUserPermissionsId(asID(userPermissions.id)).id),
          panelId: Number(
            fromControlSystemId(asID(userPermissions.controlSystem?.id))
              .systemId
          ),
          multiPanelUserCodesEnabled:
            userPermissions.multiPanelUserCodesEnabled,
          multiPanelSchedulesEnabled:
            userPermissions.multiPanelSchedulesEnabled,
          multiPanelProfilesEnabled: userPermissions.multiPanelProfilesEnabled,
          reportsEnabled: userPermissions.reportsEnabled,
          viewStoredUserCodes: userPermissions.viewStoredUserCodes,
          armingEnabled: userPermissions.armingEnabled,
          remotePanicEmergencyEnabled:
            userPermissions.remotePanicEmergencyEnabled,
          remotePanicFireEnabled: userPermissions.remotePanicFireEnabled,
          remotePanicPoliceEnabled: userPermissions.remotePanicPoliceEnabled,
        })
      )
      .reduce((acc, item) => acc.set(item.panelId.toString(), item), Map()),
  });
};
