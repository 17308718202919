/**
 *
 * Barrier Operator Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Record, OrderedMap, Seq } from "immutable";
import { ascend, prop } from "ramda";
import zwaveDevicePropsFromJson, { defaultZwaveDeviceProps } from "./zwave";
export const STATUSES = {
  OPEN: "OPEN",
  OPENING: "OPENING",
  CLOSED: "CLOSED",
  CLOSING: "CLOSING",
  STOPPED: "STOPPED",
  UNKNOWN: "UNKNOWN"
};
const createBarrierOperator = Record({
  ...defaultZwaveDeviceProps,
  status: STATUSES.UNKNOWN,
  doorType: "BARRIER_OPERATOR",
  nodeType: "BARRIER_OPERATOR"
});

const createBarrierOperatorFromJson = json =>
  createBarrierOperator({
    ...zwaveDevicePropsFromJson(json),
    status: json.status || STATUSES.UNKNOWN
  });

export default createBarrierOperatorFromJson;
export const createBarrierOperatorsFromJson = json =>
  Seq(json)
    .map(createBarrierOperatorFromJson)
    .sort(ascend(prop("name")))
    .reduce(
      (acc, barrierOperator) =>
        acc.set(barrierOperator.number, barrierOperator),
      OrderedMap()
    );
