/**
 *
 * XT Arming Schedule Model
 * @author Kyle Ellman, Chad Watson
 *
 */

import { SCHEDULE_TYPES } from "constants/index";
import { compose, assoc, omit, always } from "ramda";
import * as translate from "../translate";

const translateOptions = {
  [translate.TRANSLATE_OPTIONS.END]: true,
  [translate.TRANSLATE_OPTIONS.END_TYPE]: true
};

/**
 * Builds a schedule model
 * @param  {object} json response from API
 * @return {object}
 */
export default compose(
  assoc("type", SCHEDULE_TYPES.XT_ARMING),
  translate.fromJSON(translateOptions)
);

/**
 * translates the schedule model to API format
 * @param  {object} json response from API
 * @return {object}
 */
export const toApiObject = compose(
  translate.toJSON({ rawBeginPrefix: "open", rawEndPrefix: "close" }),
  omit(["type", "name"])
);

export const saveErrors = translate.slotsErrors;

export const newSchedule = compose(
  assoc("type", SCHEDULE_TYPES.XT_ARMING),
  translate.newSchedule,
  always(translateOptions)
);
