/**
 *
 * Area Model
 * @author Kyle Ellman, Chad Watson
 *
 */

import { __, applySpec, prop, compose } from "ramda";
import { yesNoToBool, boolToYesNo } from "utils/models";
import { toInt } from "utils";

const PRIORITIES = {
  PERIMETER: 1,
  INTERIOR: 2,
  BEDROOMS: 3
};

/**
 * Builds an Area model
 * @param  {object} json response from areas API
 * @return {object}
 */
export default applySpec({
  name: prop("name"),
  number: compose(
    toInt,
    prop("number")
  ),
  autoArm: compose(
    yesNoToBool,
    prop("auto_arm")
  ),
  autoDisarm: compose(
    yesNoToBool,
    prop("auto_disrm")
  ),
  priority: compose(
    prop(__, PRIORITIES),
    prop("name")
  )
});

export const toApiObject = applySpec({
  name: prop("name"),
  number: prop("number"),
  auto_arm: compose(
    boolToYesNo,
    prop("autoArm")
  ),
  auto_disrm: compose(
    boolToYesNo,
    prop("autoDisarm")
  )
});
