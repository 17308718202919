import moment from "moment";
import * as R from "ramda";
import { capitalize, snakeCaseToCamelCase, camelCaseToSnakeCase } from "utils";

const { __ } = R;

export const DAYS = {
  SUN: "SUN",
  MON: "MON",
  TUE: "TUE",
  WED: "WED",
  THU: "THU",
  FRI: "FRI",
  SAT: "SAT"
};

export const SLOT_TYPE_OPTIONS = {
  EVERY_DAY: "EVERY_DAY",
  WEEKDAYS: "WEEKDAYS",
  WEEKENDS: "WEEKENDS",
  ...DAYS
};

export const SLOT_END_TYPE_OPTIONS = {
  SAME_DAY: "SAME_DAY",
  NEXT_DAY: "NEXT_DAY",
  ...DAYS
};

const REPEATING_DAYS_GROUPS = {
  [SLOT_TYPE_OPTIONS.EVERY_DAY]: [
    SLOT_TYPE_OPTIONS.SUN,
    SLOT_TYPE_OPTIONS.MON,
    SLOT_TYPE_OPTIONS.TUE,
    SLOT_TYPE_OPTIONS.WED,
    SLOT_TYPE_OPTIONS.THU,
    SLOT_TYPE_OPTIONS.FRI,
    SLOT_TYPE_OPTIONS.SAT
  ],
  [SLOT_TYPE_OPTIONS.WEEKDAYS]: [
    SLOT_TYPE_OPTIONS.MON,
    SLOT_TYPE_OPTIONS.TUE,
    SLOT_TYPE_OPTIONS.WED,
    SLOT_TYPE_OPTIONS.THU,
    SLOT_TYPE_OPTIONS.FRI
  ],
  [SLOT_TYPE_OPTIONS.WEEKENDS]: [SLOT_TYPE_OPTIONS.SUN, SLOT_TYPE_OPTIONS.SAT]
};

export const SLOT_TYPE_RELATIONSHIPS = {
  [SLOT_TYPE_OPTIONS.EVERY_DAY]: [
    ...REPEATING_DAYS_GROUPS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS,
    SLOT_TYPE_OPTIONS.WEEKENDS
  ],
  [SLOT_TYPE_OPTIONS.WEEKDAYS]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    ...REPEATING_DAYS_GROUPS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.WEEKENDS]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    ...REPEATING_DAYS_GROUPS.WEEKENDS
  ],
  [SLOT_TYPE_OPTIONS.SUN]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKENDS
  ],
  [SLOT_TYPE_OPTIONS.MON]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.TUE]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.WED]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.THU]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.FRI]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKDAYS
  ],
  [SLOT_TYPE_OPTIONS.SAT]: [
    SLOT_TYPE_OPTIONS.EVERY_DAY,
    SLOT_TYPE_OPTIONS.WEEKENDS
  ]
};

const useTwilightSchedulingKey = R.compose(
  R.concat(__, "UseTwilightScheduling"),
  R.defaultTo("")
);
export const twilightPeriodKey = R.compose(
  R.concat(__, "TwilightPeriod"),
  R.defaultTo("")
);
export const twilightOffsetKey = R.compose(
  R.concat(__, "TwilightOffset"),
  R.defaultTo("")
);

export const TRANSLATED_KEYS = {
  BEGIN: "begin",
  END: "end",
  SLOTS: "slots",
  TYPE: "type",
  END_TYPE: "endType"
};
TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING = useTwilightSchedulingKey(
  TRANSLATED_KEYS.BEGIN
);
TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING = useTwilightSchedulingKey(
  TRANSLATED_KEYS.END
);
TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD = twilightPeriodKey(
  TRANSLATED_KEYS.BEGIN
);
TRANSLATED_KEYS.END_TWILIGHT_PERIOD = twilightPeriodKey(TRANSLATED_KEYS.END);
TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET = twilightOffsetKey(
  TRANSLATED_KEYS.BEGIN
);
TRANSLATED_KEYS.END_TWILIGHT_OFFSET = twilightOffsetKey(TRANSLATED_KEYS.END);

export const TWILIGHT_OPTION_KEYS = [
  TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD,
  TRANSLATED_KEYS.END_TWILIGHT_PERIOD,
  TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET,
  TRANSLATED_KEYS.END_TWILIGHT_OFFSET
];

export const TRANSLATE_OPTIONS = {
  END: "END",
  END_TYPE: "END_TYPE",
  TWILIGHT: "TWILIGHT"
};

const format = R.invoker(1, "format");

const dayTimeFromDate = R.when(
  R.complement(R.isEmpty),
  R.compose(format("HHmmss"), moment.utc)
);

const dayIndexFromDate = R.compose(R.invoker(0, "day"), moment.utc);

const dayNameFromDate = R.compose(R.toUpper, format("ddd"), moment.utc);

const slotDayIndex = R.indexOf(__, REPEATING_DAYS_GROUPS.EVERY_DAY);

const slotTypeLens = R.lensProp(TRANSLATED_KEYS.TYPE);
const slotEndTypeLens = R.lensProp(TRANSLATED_KEYS.END_TYPE);
const slotsLens = R.lensProp(TRANSLATED_KEYS.SLOTS);
const beginLens = R.lensProp(TRANSLATED_KEYS.BEGIN);
const endLens = R.lensProp(TRANSLATED_KEYS.END);
const beginUseTwilightSchedulingLens = R.lensProp(
  TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING
);
const endUseTwilightSchedulingLens = R.lensProp(
  TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING
);

const daySchedulesRepeat = R.curry((groupDays, accumulation) =>
  R.compose(
    R.all(
      R.allPass([
        R.apply(R.useWith(R.and, [Boolean, Boolean])),
        R.ifElse(
          R.all(R.propEq(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING, true)),
          R.apply(R.eqProps(TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD)),
          R.ifElse(
            R.all(
              R.either(
                R.complement(
                  R.has(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING)
                ),
                R.propEq(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING, false)
              )
            ),
            R.compose(
              R.apply(R.equals),
              R.map(R.compose(dayTimeFromDate, R.prop(TRANSLATED_KEYS.BEGIN)))
            ),
            R.always(false)
          )
        ),
        R.ifElse(
          R.all(R.has(TRANSLATED_KEYS.END)),
          R.ifElse(
            R.all(R.propEq(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING, true)),
            R.apply(R.eqProps(TRANSLATED_KEYS.END_TWILIGHT_PERIOD)),
            R.ifElse(
              R.all(
                R.either(
                  R.complement(
                    R.has(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING)
                  ),
                  R.propEq(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING, false)
                )
              ),
              R.allPass([
                R.compose(
                  R.apply(R.equals),
                  R.map(R.compose(dayTimeFromDate, R.prop(TRANSLATED_KEYS.END)))
                ),
                R.ifElse(
                  R.all(R.has(TRANSLATED_KEYS.END_TYPE)),
                  R.apply(R.eqProps(TRANSLATED_KEYS.END_TYPE)),
                  R.always(true)
                )
              ]),
              R.always(false)
            )
          ),
          R.always(true)
        )
      ])
    ),
    R.aperture(2),
    R.map(R.prop(__, accumulation))
  )(groupDays)
);

const rawEndDayIndex = R.compose(
  dayIndexFromDate,
  R.prop(TRANSLATED_KEYS.END),
  R.head
);

const compareBeginAndEndDays = comparator =>
  R.lift(comparator)(rawEndDayIndex, R.compose(slotDayIndex, R.nth(1)));

const isLastDay = R.compose(
  R.equals(R.indexOf(SLOT_TYPE_OPTIONS.SAT, REPEATING_DAYS_GROUPS.EVERY_DAY)),
  slotDayIndex,
  R.last
);

const endDayIsSunday = R.compose(
  R.equals(R.indexOf(SLOT_TYPE_OPTIONS.SUN, REPEATING_DAYS_GROUPS.EVERY_DAY)),
  rawEndDayIndex
);

const getEndType = R.cond([
  [
    R.compose(R.or(R.isEmpty, R.isNil), R.prop(TRANSLATED_KEYS.END), R.head),
    R.always(SLOT_END_TYPE_OPTIONS.SAME_DAY)
  ],
  [compareBeginAndEndDays(R.equals), R.always(SLOT_END_TYPE_OPTIONS.SAME_DAY)],
  [
    compareBeginAndEndDays(R.compose(R.equals(1), R.subtract)),
    R.always(SLOT_END_TYPE_OPTIONS.NEXT_DAY)
  ],
  [
    R.lift(R.and)(isLastDay, endDayIsSunday),
    R.always(SLOT_END_TYPE_OPTIONS.NEXT_DAY)
  ],
  [
    R.T,
    R.compose(
      R.prop(__, SLOT_END_TYPE_OPTIONS),
      dayNameFromDate,
      R.prop(TRANSLATED_KEYS.END),
      R.head
    )
  ]
]);

const setEndType = R.compose(
  R.ifElse(
    R.lift(R.has(TRANSLATED_KEYS.END))(R.head),
    R.converge(R.assoc(TRANSLATED_KEYS.END_TYPE), [getEndType, R.head]),
    R.head
  ),
  R.pair
);

const twilightPeriod = R.converge(R.compose, [
  R.compose(R.ifElse(R.isNil, R.always("")), R.prop, twilightPeriodKey),
  R.compose(R.find, R.prop, useTwilightSchedulingKey)
]);

const twilightOffset = R.converge(R.compose, [
  R.compose(R.ifElse(R.isNil, R.always(0)), R.prop, twilightOffsetKey),
  R.compose(R.find, R.prop, useTwilightSchedulingKey)
]);

const normalizeRawKey = R.converge(R.update(0), [
  R.compose(
    snakeCaseToCamelCase,
    R.replace(/^(close|off|arm)/, TRANSLATED_KEYS.END),
    R.replace(/^(open|a_time|on|disarm)/, TRANSLATED_KEYS.BEGIN),
    R.slice(4, Infinity),
    R.head
  ),
  R.identity
]);

const restoreRawKey = ({ rawBeginPrefix, rawEndPrefix }) =>
  R.converge(R.update(0), [
    R.compose(
      R.when(
        R.always(rawEndPrefix),
        R.replace(TRANSLATED_KEYS.END, rawEndPrefix)
      ),
      R.replace(TRANSLATED_KEYS.BEGIN, rawBeginPrefix),
      camelCaseToSnakeCase,
      R.head
    ),
    R.identity
  ]);

const groupRepeatingDays = repeatingGroupKey =>
  R.when(
    daySchedulesRepeat(REPEATING_DAYS_GROUPS[repeatingGroupKey]),
    R.converge(R.merge, [
      R.omit(REPEATING_DAYS_GROUPS[repeatingGroupKey]),
      R.applySpec({
        [repeatingGroupKey]: R.prop(
          R.head(REPEATING_DAYS_GROUPS[repeatingGroupKey])
        )
      })
    ])
  );

const daySlotKeyFromRawDataRow = R.compose(R.toUpper, R.take(3), R.head);

const parseRawSchedule = (options = {}) =>
  R.compose(
    R.values,
    R.mapObjIndexed(R.flip(R.assoc(TRANSLATED_KEYS.TYPE))),
    groupRepeatingDays(SLOT_TYPE_OPTIONS.WEEKENDS),
    groupRepeatingDays(SLOT_TYPE_OPTIONS.WEEKDAYS),
    groupRepeatingDays(SLOT_TYPE_OPTIONS.EVERY_DAY),
    options[TRANSLATE_OPTIONS.END_TYPE]
      ? R.mapObjIndexed(setEndType)
      : R.identity,
    R.map(R.compose(R.fromPairs, R.map(normalizeRawKey))),
    R.groupBy(daySlotKeyFromRawDataRow),
    R.filter(
      R.compose(
        R.contains(__, REPEATING_DAYS_GROUPS.EVERY_DAY),
        daySlotKeyFromRawDataRow
      )
    ),
    R.toPairs
  );

const slotIsUnavailableIn = currentSlotTypes =>
  R.anyPass([
    R.contains(__, currentSlotTypes),
    R.compose(
      R.any(R.contains(__, currentSlotTypes)),
      R.prop(__, SLOT_TYPE_RELATIONSHIPS)
    )
  ]);

export const slotTwilightDefaults = type => ({
  [useTwilightSchedulingKey(type)]: false // eslint-disable-line
});

const endDefaults = {
  end: "",
  endType: SLOT_END_TYPE_OPTIONS.SAME_DAY
};

const setDateDay = R.curry((day, dateString) =>
  moment.utc(dateString).year(2000).month(0).date(2).day(day).toISOString()
  ??
  ""
);

const repeatingDaysGroup = R.compose(
  R.prop(__, REPEATING_DAYS_GROUPS),
  R.view(slotTypeLens)
);

const resolveBegin = R.converge(R.over(beginLens), [
  R.compose(setDateDay, slotDayIndex, R.view(slotTypeLens)),
  R.identity
]);

const expandRepeatingDaysSlot = R.converge(R.map, [
  R.compose(R.flip(R.set(slotTypeLens)), R.identity),
  repeatingDaysGroup
]);

const emptySlotDefaults = {
  type: SLOT_TYPE_OPTIONS.EVERY_DAY,
  begin: "",
  end: "",
  endType: SLOT_TYPE_OPTIONS.SAME_DAY,
  beginUseTwilightScheduling: false,
  endUseTwilightScheduling: false,
  beginTwilightOffset: 0,
  endTwilightOffset: 0,
  beginTwilightPeriod: "",
  endTwilightPeriod: ""
};

const emptySlotFromCurrentSlotsShape = R.ifElse(
  R.isEmpty,
  R.always(R.always(emptySlotDefaults)),
  R.compose(
    R.flip(R.set(slotTypeLens)),
    R.fromPairs,
    R.map(
      R.converge(R.update(1), [
        R.compose(R.prop(__, emptySlotDefaults), R.head),
        R.identity
      ])
    ),
    R.toPairs,
    R.head
  )
);

const undefinedDays = R.compose(
  R.reject(__, REPEATING_DAYS_GROUPS.EVERY_DAY),
  R.flip(R.contains),
  R.map(R.view(slotTypeLens))
);

const fillEmptySlots = R.converge(R.concat, [
  R.identity,
  R.converge(R.map, [emptySlotFromCurrentSlotsShape, undefinedDays])
]);

const rawDataKeyFromSlotType = R.useWith(R.concat, [R.toLower, capitalize]);

const prefixKeyWithSlotType = slotType =>
  R.converge(R.update(0), [
    R.compose(rawDataKeyFromSlotType(slotType), R.head),
    R.identity
  ]);

export const slotTypeIndex = R.compose(
  R.indexOf(__, R.values(SLOT_TYPE_OPTIONS)),
  R.prop(TRANSLATED_KEYS.TYPE)
);

const collectErrorData = R.compose(
  R.map(R.compose(R.fromPairs, R.map(normalizeRawKey))),
  R.groupBy(daySlotKeyFromRawDataRow),
  R.toPairs,
  R.map(R.when(R.complement(R.isNil), R.head))
);

const resolveSameDayEndType = R.converge(R.over(endLens), [
  R.compose(setDateDay, slotDayIndex, R.view(slotTypeLens)),
  R.identity
]);

const resolveNextDayEndType = R.converge(R.over(endLens), [
  R.compose(setDateDay, R.inc, slotDayIndex, R.view(slotTypeLens)),
  R.identity
]);

const resolveSpecificDayEndType = R.converge(R.over(endLens), [
  R.compose(setDateDay, slotDayIndex, R.view(slotEndTypeLens)),
  R.identity
]);

const resolveEnd = R.compose(
  R.omit([TRANSLATED_KEYS.END_TYPE]),
  R.cond([
    [R.compose(R.not, R.has(TRANSLATED_KEYS.END_TYPE)), resolveSameDayEndType],
    [
      R.propEq(TRANSLATED_KEYS.END_TYPE, SLOT_END_TYPE_OPTIONS.SAME_DAY),
      resolveSameDayEndType
    ],
    [
      R.propEq(TRANSLATED_KEYS.END_TYPE, SLOT_END_TYPE_OPTIONS.NEXT_DAY),
      resolveNextDayEndType
    ],
    [R.T, resolveSpecificDayEndType]
  ])
);

const isRepeatingDaySlot = R.compose(
  R.has(__, REPEATING_DAYS_GROUPS),
  R.view(slotTypeLens)
);

const expandSlots = R.compose(
  R.mergeAll,
  R.map(
    R.compose(
      R.fromPairs,
      R.converge(R.map, [
        R.compose(prefixKeyWithSlotType, R.view(slotTypeLens)),
        R.compose(R.toPairs, R.omit([TRANSLATED_KEYS.TYPE]))
      ])
    )
  ),
  fillEmptySlots,
  R.map(resolveEnd),
  R.map(resolveBegin),
  R.flatten,
  R.map(R.when(isRepeatingDaySlot, expandRepeatingDaysSlot))
);

const translatedScheduleHasTwilight = R.allPass(
  R.map(R.complement(R.propEq(__, null)), TWILIGHT_OPTION_KEYS)
);

const applyTwilightOptionsToSlot = twilightOptions =>
  R.compose(
    R.when(
      R.prop(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING),
      R.compose(
        R.over(
          R.lensProp(TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET),
          R.defaultTo(0)
        ),
        R.merge(
          __,
          R.pick(
            [
              TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD,
              TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET
            ],
            twilightOptions
          )
        ),
        R.set(beginLens, "")
      )
    ),
    R.when(
      R.prop(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING),
      R.compose(
        R.over(R.lensProp(TRANSLATED_KEYS.END_TWILIGHT_OFFSET), R.defaultTo(0)),
        R.merge(
          __,
          R.pick(
            [
              TRANSLATED_KEYS.END_TWILIGHT_PERIOD,
              TRANSLATED_KEYS.END_TWILIGHT_OFFSET
            ],
            twilightOptions
          )
        ),
        R.set(endLens, "")
      )
    )
  );

const applyTwilightOptionsToSlots = R.converge(R.over(slotsLens), [
  R.compose(R.map, applyTwilightOptionsToSlot, R.pick(TWILIGHT_OPTION_KEYS)),
  R.omit(TWILIGHT_OPTION_KEYS)
]);

const pruneSlots = R.reject(
  R.allPass([
    R.lift(R.complement(R.equals(SLOT_TYPE_OPTIONS.EVERY_DAY)))(
      R.view(slotTypeLens)
    ),
    R.lift(R.isEmpty)(R.view(beginLens)),
    R.either(
      R.complement(R.has(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING)),
      R.lift(R.equals(false))(R.view(beginUseTwilightSchedulingLens))
    ),
    R.either(
      R.complement(R.has(TRANSLATED_KEYS.END)),
      R.lift(R.isEmpty)(R.view(endLens))
    ),
    R.either(
      R.complement(R.has(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING)),
      R.lift(R.equals(false))(R.view(endUseTwilightSchedulingLens))
    )
  ])
);

const baseScheduleKeysToPick = R.compose(
  R.keys,
  R.filter(R.equals(true)),
  R.applySpec({
    [TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD]: R.prop(TRANSLATE_OPTIONS.TWILIGHT),
    [TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET]: R.prop(TRANSLATE_OPTIONS.TWILIGHT),
    [TRANSLATED_KEYS.END_TWILIGHT_PERIOD]: R.both(
      R.prop(TRANSLATE_OPTIONS.TWILIGHT),
      R.prop(TRANSLATE_OPTIONS.END)
    ),
    [TRANSLATED_KEYS.END_TWILIGHT_OFFSET]: R.both(
      R.prop(TRANSLATE_OPTIONS.TWILIGHT),
      R.prop(TRANSLATE_OPTIONS.END)
    )
  })
);

const slotKeysToPick = R.compose(
  R.keys,
  R.filter(R.equals(true)),
  R.applySpec({
    [TRANSLATED_KEYS.BEGIN]: R.always(true),
    [TRANSLATED_KEYS.TYPE]: R.always(true),
    [TRANSLATED_KEYS.END]: R.prop(TRANSLATE_OPTIONS.END),
    [TRANSLATED_KEYS.END_TYPE]: R.prop(TRANSLATE_OPTIONS.END_TYPE),
    [TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING]: R.prop(
      TRANSLATE_OPTIONS.TWILIGHT
    ),
    [TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING]: R.both(
      R.prop(TRANSLATE_OPTIONS.TWILIGHT),
      R.prop(TRANSLATE_OPTIONS.END)
    )
  })
);

export const availableSlots = R.compose(
  R.lift(R.reject(__, R.values(SLOT_TYPE_OPTIONS)))(slotIsUnavailableIn),
  R.map(R.view(slotTypeLens)),
  R.defaultTo([]),
  R.view(slotsLens)
);

export const baseEmptySlot = R.applySpec({
  type: R.compose(R.head, availableSlots),
  begin: R.always("")
});

export const emptySlotWithEnd = R.compose(R.merge(endDefaults), baseEmptySlot);

export const emptySlotWithTwilight = R.compose(
  R.merge(slotTwilightDefaults(TRANSLATED_KEYS.BEGIN)),
  baseEmptySlot
);

export const emptySlotWithEndAndTwilight = R.compose(
  R.mergeAll,
  R.prepend(__, [
    slotTwilightDefaults(TRANSLATED_KEYS.BEGIN),
    slotTwilightDefaults(TRANSLATED_KEYS.END)
  ]),
  emptySlotWithEnd
);

const emptySlot = R.cond([
  [
    R.compose(R.all(__, TWILIGHT_OPTION_KEYS), R.flip(R.has)),
    emptySlotWithEndAndTwilight
  ],
  [
    R.compose(
      R.all(__, [twilightPeriodKey("begin"), twilightOffsetKey("begin")]),
      R.flip(R.has)
    ),
    emptySlotWithTwilight
  ],
  [
    R.compose(R.has(TRANSLATED_KEYS.END), R.head, R.view(slotsLens)),
    emptySlotWithEnd
  ],
  [R.T, baseEmptySlot]
]);

export const sortSlots = R.when(
  R.complement(R.isNil),
  R.sort(R.useWith(R.subtract, [slotTypeIndex, slotTypeIndex]))
);

export const slotsErrors = ({ schedule, errors }) => {
  const collectedErrorData = collectErrorData(errors);

  return schedule.slots.reduce((result, { type }) => {
    const errorKey = [type, ...SLOT_TYPE_RELATIONSHIPS[type]].find(
      slotType => collectedErrorData[slotType]
    );
    return errorKey
      ? R.assoc(type, collectedErrorData[errorKey], result)
      : result;
  }, {});
};

export const addSlot = schedule =>
  R.over(
    slotsLens,
    R.compose(sortSlots, R.append(emptySlot(schedule))),
    schedule
  );

export const fromJSON = (options = {}) =>
  R.compose(
    R.over(slotsLens, R.compose(sortSlots, pruneSlots)),
    R.converge(R.merge, [
      R.applySpec({
        slots: R.map(R.omit(TWILIGHT_OPTION_KEYS))
      }),
      R.compose(
        R.pick(baseScheduleKeysToPick(options)),
        R.applySpec({
          [TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD]: twilightPeriod(
            TRANSLATED_KEYS.BEGIN
          ),
          [TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET]: twilightOffset(
            TRANSLATED_KEYS.BEGIN
          ),
          [TRANSLATED_KEYS.END_TWILIGHT_PERIOD]: twilightPeriod(
            TRANSLATED_KEYS.END
          ),
          [TRANSLATED_KEYS.END_TWILIGHT_OFFSET]: twilightOffset(
            TRANSLATED_KEYS.END
          )
        })
      )
    ]),
    parseRawSchedule(options)
  );

export const toJSON = ({ rawBeginPrefix, rawEndPrefix }) =>
  R.compose(
    R.fromPairs,
    R.map(restoreRawKey({ rawBeginPrefix, rawEndPrefix })),
    R.toPairs,
    R.converge(R.merge, [R.omit([TRANSLATED_KEYS.SLOTS]), R.view(slotsLens)]),
    R.over(slotsLens, expandSlots),
    R.when(translatedScheduleHasTwilight, applyTwilightOptionsToSlots)
  );

export const newSchedule = R.converge(R.merge, [
  R.compose(
    R.applySpec({
      slots: R.of
    }),
    R.converge(R.pick, [
      slotKeysToPick,
      R.always({
        [TRANSLATED_KEYS.TYPE]: SLOT_TYPE_OPTIONS.EVERY_DAY,
        [TRANSLATED_KEYS.BEGIN]: "",
        [TRANSLATED_KEYS.END]: "",
        [TRANSLATED_KEYS.END_TYPE]: SLOT_END_TYPE_OPTIONS.SAME_DAY,
        [TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING]: false,
        [TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING]: false
      })
    ])
  ),
  R.converge(R.pick, [
    baseScheduleKeysToPick,
    R.always({
      [TRANSLATED_KEYS.BEGIN_TWILIGHT_PERIOD]: "",
      [TRANSLATED_KEYS.BEGIN_TWILIGHT_OFFSET]: 0,
      [TRANSLATED_KEYS.END_TWILIGHT_PERIOD]: "",
      [TRANSLATED_KEYS.END_TWILIGHT_OFFSET]: 0
    })
  ])
]);
