/**
 *
 * Favorite Model
 * @author Chad Watson
 *
 *
 */
import { compose, applySpec, prop, map, ascend, cond, has, T } from "ramda";
import { Seq, Record, OrderedMap } from "immutable";
import {
  toInt,
  immutableToArray,
  immutableValueSeq,
  immutableToJS
} from "utils";
import { STATUSES as LOCK_STATUSES } from "models/lock";
import { STATUSES as BARRIER_OPERATOR_STATUSES } from "models/barrierOperator";
import {
  MODES as THERMOSTAT_MODES,
  FAN_MODES as THERMOSTAT_FAN_MODES,
  defaultCoolingPoint,
  defaultHeatingPoint
} from "models/thermostat";
export const createLightFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  level: 0
});
export const createLockFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  status: LOCK_STATUSES.UNKNOWN
});
export const createThermostatFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  mode: THERMOSTAT_MODES.OFF,
  fan_mode: THERMOSTAT_FAN_MODES.AUTO,
  setpoint_cooling: defaultCoolingPoint,
  setpoint_heating: defaultHeatingPoint
});
export const createBarrierOperatorFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  status: BARRIER_OPERATOR_STATUSES.UNKNOWN
});
const createFavoriteAction = cond([
  [has("level"), createLightFavoriteAction],
  [has("status"), createLockFavoriteAction],
  [has("setpoint_cooling"), createThermostatFavoriteAction],
  [T, createBarrierOperatorFavoriteAction]
]);
export const createFavorite = Record({
  id: NaN,
  number: 0,
  name: "",
  icon: "FAVORITE",
  actions: OrderedMap(),
  updating: false,
  updateError: false,
  activating: false,
  activateError: false,
  deleting: false,
  deleteError: false
});
export const createFavoriteFromJson = json =>
  createFavorite({
    id: json.id,
    number: toInt(json.number),
    name: json.name,
    icon: json.app_icon,
    actions: Seq(json.actions || [])
      .map(createFavoriteAction)
      .reduce(
        (acc, action) => acc.set(action.node_number, action),
        OrderedMap()
      )
  });
export default createFavoriteFromJson;
export const createFavoritesFromJson = json =>
  Seq(json)
    .map(createFavoriteFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, favorite) => acc.set(favorite.id, favorite), OrderedMap());

const actionToJson = ({
  id,
  node_number: nodeNumber,
  included,
  ...settings
}) => ({
  id: !isNaN(id) ? id : undefined,
  node_number: nodeNumber,
  _destroy: included ? undefined : true,
  json_settings: JSON.stringify(settings)
});

export const apiRequestObject = applySpec({
  name: prop("name"),
  app_icon: prop("icon"),
  actions_attributes: compose(
    immutableToArray,
    map(
      compose(
        actionToJson,
        immutableToJS
      )
    ),
    immutableValueSeq,
    prop("actions")
  )
});
