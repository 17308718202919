import { OrderedMap, Seq, Record, Map } from "immutable";
import { compose, ascend, prop, cond, equals, always, T } from "ramda";
import { DOOR_TYPES } from "constants/index";
import { yesNoToBool } from "utils/models";
import { createOutputPropsFromJson } from "./output";
import graphql from "babel-plugin-relay/macro";
import { readInlineData } from "react-relay";

export const DOOR_STATUSES = {
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
  UNKNOWN: "UNKNOWN",
};

export const createDoor = Record({
  name: "",
  isOn: false,
  number: 0,
  override: false,
  isPublic: false,
  private: false,
  realTimeStatus: false,
  tracked: false,
  error: null,
  doorType: DOOR_TYPES.DOOR,
  status: null,
});

export const createDoorFromJson = (json) =>
  createDoor({
    ...createOutputPropsFromJson(json),
    override: yesNoToBool(json.override),
    isPublic: yesNoToBool(json.public_dr),
    private: yesNoToBool(json.private_door),
    realTimeStatus: yesNoToBool(json.door_rts),
    tracked: json.tracked,
    error: null,
    doorType: DOOR_TYPES.DOOR,
  });

export default createDoorFromJson;

export const createDoorsFromJson = (allDoors) =>
  allDoors
    .map(createDoorFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, door) => acc.set(door.number, door), OrderedMap());

export const normalizeScapiDoorsfromJson = (json) =>
  Seq(json).map(prop("device_information")).update(createDoorsFromJson);

const getDoorStatusFromOutputStatusJson = compose(
  cond([
    [equals("STEADY"), always(DOOR_STATUSES.UNLOCKED)],
    [equals("OFF"), always(DOOR_STATUSES.LOCKED)],
    [T, always(DOOR_STATUSES.UNKNOWN)],
  ]),
  prop("status")
);

export const createOutputStatusesByNumberFromOutputStatusesJson = (json) =>
  json.reduce(
    (acc, outputJson) =>
      acc.set(outputJson.number, getDoorStatusFromOutputStatusJson(outputJson)),
    Map()
  );

/**
 * @param { import("./__generated__/doorModel_controlSystemDoorsConnection.graphql").doorModel_controlSystemDoorsConnection$key } key
 * @returns {OrderedMap<number, ReturnType<createDoor>>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment doorModel_controlSystemDoorsConnection on ControlSystemDoorsConnection
      @inline {
        edges {
          node {
            id
            name
            number
            override
            isPublic
            isPrivate
            realTimeStatus
            tracked
            status
          }
        }
      }
    `,
    key
  );

  return Seq(data.edges)
    .map(({ node }) =>
      createDoor({
        name: node.name,
        isOn: false,
        number: node.number,
        override: node.override,
        isPublic: node.isPublic,
        private: node.isPrivate,
        realTimeStatus: node.realTimeStatus,
        tracked: node.tracked,
        doorType: DOOR_TYPES.DOOR,
      })
    )
    .sort(ascend(prop("name")))
    .reduce((acc, door) => acc.set(door.number, door), OrderedMap());
};
