/**
 *
 * Arming Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { Seq, Map, Record, Set } from "immutable";
import {
  any,
  anyPass,
  compose,
  curry,
  equals,
  find,
  groupBy,
  prop,
  propEq,
  propSatisfies,
  either,
} from "ramda";
import { safeArrayOfInts, parseStringList } from "utils/index";

export const ARMING_TYPES = {
  AREA: "AREA",
  HSA: "HSA",
  HA: "HA",
  AP: "AP",
  HSAG: "HSAWG",
};
const createAreaStatus = Record({
  armed: false,
  burgAlarm: false,
  name: "",
  number: 0,
});

const createAreaStatusFromJson = (json) =>
  createAreaStatus({
    armed: json.armed_status === "armed",
    burgAlarm: json.burg_alarm === "1",
    name: json.name,
    number: parseInt(json.number, 10),
  });

export const createArmedStatus = Record({
  areaStatuses: Maybe.Nothing(),
  armedAreas: Maybe.Nothing(),
  armedMode: "",
  armingSystem: "",
  burgAlarm: false,
});
export const isSupportedArmedStatus = compose(
  anyPass([
    equals(ARMING_TYPES.AREA),
    equals(ARMING_TYPES.HSA),
    equals(ARMING_TYPES.HA),
    equals(ARMING_TYPES.AP),
  ]),
  prop("arming_system")
);
const areaStatusIsArmed = either(
  propEq("arm_stat", "Y"),
  propEq("arm_stat", "B")
);
export const createArmedStatusFromJson = (json) =>
  createArmedStatus({
    areaStatuses: Maybe.fromNullable(json.area_statuses).map(
      compose((areaStatuses) =>
        Seq(areaStatuses)
          .map(createAreaStatusFromJson)
          .reduce(
            (acc, areaStatus) => acc.set(areaStatus.number, areaStatus),
            Map()
          )
      )
    ),
    armedAreas: Maybe.fromNullable(json.armed_areas).map(
      compose(Set, safeArrayOfInts, parseStringList)
    ),
    armedMode: json.armed_mode,
    armingSystem: json.arming_system,
    burgAlarm: json.burg_alarm === "1",
  });
const createBadZone = Record({
  name: "",
  number: 0,
});
export const createBadZoneFromJson = ({ name, number }) =>
  createBadZone({
    name,
    number,
  });
export const createBadZonesFromJson = (json) =>
  Seq(json).map(createBadZoneFromJson).toList();
const isAreaArmed = curry((areaNumber, areaStatuses) =>
  Maybe.fromNullable(
    find(
      propSatisfies((number) => Number(number) === areaNumber, "number"),
      areaStatuses
    )
  )
    .map(areaStatusIsArmed)
    .getOrElse(false)
);
const isBedroomArmed = isAreaArmed(3);
const isInteriorArmed = isAreaArmed(2);
const isPerimeterArmed = isAreaArmed(1);

const getHSAArmedMode = (areaStatuses, system) =>
  isBedroomArmed(areaStatuses)
    ? "AWAY"
    : isInteriorArmed(areaStatuses)
    ? system.get("armingType") === "HA"
      ? "AWAY"
      : system.get("armingType") !== "AP"
      ? "SLEEP"
      : "ALL"
    : isPerimeterArmed(areaStatuses)
    ? system.get("armingType") !== "AP"
      ? "HOME"
      : "PERIMETER"
    : "OFF";

const getAreaArmedMode = (areaStatuses) =>
  any(areaStatusIsArmed, areaStatuses) ? "ARMED" : "OFF";

const getBurgAlarm = any(
  either(propEq("alrm_stat", "Y"), propEq("arm_stat", "B"))
);

const getArmedAreas = (areaStatuses) =>
  Set(
    areaStatuses
      .filter(areaStatusIsArmed)
      .map(prop("number"))
      .map((number) => parseInt(number, 10))
  );

const getAreaStatuses = (areaStatuses) =>
  Map(
    areaStatuses.map((status) => [
      parseInt(status.number, 10),
      createAreaStatus({
        armed: status.arm_stat === "Y" || status.arm_stat === "B",
        burgAlarm: status.alrm_stat === "Y" || status.arm_stat === "B",
        name: status.name,
        number: parseInt(status.number, 10),
      }),
    ])
  );

export const normalizeOdataArmedStatuses = (systems, value) => {
  const armedStatuses = groupBy(prop("panel_id"), value);
  const HSASystems = systems
    .filter((system) => system.get("armingType") !== ARMING_TYPES.AREA)
    .map((system) =>
      system.setIn(
        ["arming", "armedStatus"],
        Maybe.fromNullable(armedStatuses[system.get("id")])
          .map((areaStatuses) => ({
            armedMode: getHSAArmedMode(areaStatuses, system),
            armingSystem: system.get("armingType"),
            burgAlarm: getBurgAlarm(areaStatuses),
            areaStatuses: Maybe.Nothing(),
            armedAreas: Maybe.Nothing(),
          }))
          .map(createArmedStatus)
      )
    );
  const areaSystems = systems
    .filter((system) => system.get("armingType") === ARMING_TYPES.AREA)
    .map((system) =>
      system.setIn(
        ["arming", "armedStatus"],
        Maybe.fromNullable(armedStatuses[system.get("id")])
          .map((areaStatuses) => ({
            armedMode: getAreaArmedMode(areaStatuses),
            armingType: system.get("armingType"),
            burgAlarm: getBurgAlarm(areaStatuses),
            areaStatuses: Maybe.of(getAreaStatuses(areaStatuses)),
            armedAreas: Maybe.of(getArmedAreas(areaStatuses)),
          }))
          .map(createArmedStatus)
      )
    );
  return areaSystems
    .map((system) => system.getIn(["arming", "armedStatus"]))
    .merge(HSASystems.map((system) => system.getIn(["arming", "armedStatus"])));
};
