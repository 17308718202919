/**
 *
 * System Area Information Model
 * @author Kyle Ellman
 *
 */

import { applySpec, compose, prop } from "ramda";
import { yesNoToBool, boolToYesNo } from "utils/models";

/**
 * Builds the system area information model
 * @param  {object} json response from system area information API
 * @return {object}
 */
export default applySpec({
  areaSchedules: compose(
    yesNoToBool,
    prop("area_sch")
  ),
  closingCheck: compose(
    yesNoToBool,
    prop("close_chk")
  )
});

export const toApiObject = applySpec({
  area_sch: compose(
    boolToYesNo,
    prop("areaSchedules")
  ),
  close_chk: compose(
    boolToYesNo,
    prop("closingCheck")
  )
});
