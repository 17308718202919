import { provideContext } from "react-remote-resource";
import {
  useAuthToken,
  useAuthTokenForSystem,
  useAuthTokenForActiveSystem,
  useAuthTokenForCustomer,
  useAuthTokenForActiveCustomer,
  useActiveSiteId,
  useAuthTokenForActiveSite,
} from "containers/LoggedInView/Context";

export {
  useAuthToken,
  useAuthTokenForSystem,
  useAuthTokenForActiveSystem,
  useAuthTokenForActiveCustomer,
  useAuthTokenForSite,
} from "containers/LoggedInView/Context";

export const provideAuthToken = provideContext(() => useAuthToken());

export const provideAuthTokenForSystem = provideContext((systemId) =>
  useAuthTokenForSystem(systemId)
);

export const provideAuthTokenForActiveSystem = provideContext(() => {
  const activeSiteId = useActiveSiteId();
  const authTokenForActiveSystem = useAuthTokenForActiveSystem();
  const authTokenForActiveSite = useAuthTokenForActiveSite();

  return activeSiteId ? authTokenForActiveSite : authTokenForActiveSystem;
});

export const provideAuthTokenForCustomer = provideContext((customerId) =>
  useAuthTokenForCustomer(customerId)
);

export const provideAuthTokenForActiveCustomer = provideContext(() =>
  useAuthTokenForActiveCustomer()
);
