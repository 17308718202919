/**
 *
 * XT Arming Schedule Model
 * @author Kyle Ellman, Chad Watson
 *
 */

import {
  applySpec,
  prop,
  converge,
  call,
  compose,
  always,
  merge,
  omit,
  identity,
  defaultTo,
  path
} from "ramda";
import { SCHEDULE_TYPES } from "constants/index";
import * as translate from "../translate";

const translateOptions = applySpec({
  [translate.TRANSLATE_OPTIONS.TWILIGHT]: prop("hasTwilight")
});

/**
 * Builds a schedule model
 * @param  {object} json response from API
 * @return {object}
 */
export default converge(merge, [
  converge(call, [compose(translate.fromJSON, translateOptions), identity]),
  applySpec({
    number: compose(parseInt, prop("number")),
    updating: always(false),
    deleting: always(false),
    type: always(SCHEDULE_TYPES.XT_FAVORITE)
  })
]);

export const newSchedule = converge(merge, [
  applySpec({
    number: prop("number"),
    type: always(SCHEDULE_TYPES.XT_FAVORITE)
  }),
  compose(translate.newSchedule, translateOptions)
]);

/**
 * translates the schedule model to API format
 * @param  {object} json response from API
 * @return {object}
 */
export const toApiObject = compose(
  translate.toJSON({ rawBeginPrefix: "a_time" }),
  omit(["type", "updating", "deleting"])
);

export const saveErrors = applySpec({
  base: compose(defaultTo([]), path(["errors", "base"])),
  slots: translate.slotsErrors
});
