import { Seq, OrderedMap, Record } from "immutable";
import graphql from "babel-plugin-relay/macro";
import { readInlineData } from "react-relay";
import { fromOutputId } from "securecom-graphql/client";

const createTrackedOutput = Record({
  id: 0,
  panelId: 0,
  name: "",
  number: 0,
  action: null,
});

export const createTrackedOutputFromJson = (json) =>
  createTrackedOutput({
    id: json.id,
    panelId: parseInt(json.panel_id, 10),
    name: json.name,
    number: parseInt(json.number, 10),
    action: json.action,
  });

export const createTrackedOutputsFromJson = (json) =>
  Seq(json)
    .map(createTrackedOutputFromJson)
    .reduce((acc, item) => acc.set(item.number, item), OrderedMap());

/**
 * @param {number} systemId
 * @param { import("./__generated__/TrackedOutputModel_controlSystemOutputsConnection.graphql").TrackedOutputModel_controlSystemOutputsConnection$key } key
 * @returns {OrderedMap<number, ReturnType<createTrackedOutput>>}
 */
export const convertGqlDataToReduxData = (systemId, key) => {
  const data = readInlineData(
    graphql`
      fragment TrackedOutputModel_controlSystemOutputsConnection on ControlSystemOutputsConnection
      @inline {
        edges {
          node {
            id
            name
            number
            action
          }
        }
      }
    `,
    key
  );

  return Seq(data.edges)
    .map(({ node }) =>
      createTrackedOutput({
        id: Number(fromOutputId(node.id).number), // TODO: Make sure this doesn't need to be a real DB ID
        panelId: systemId,
        name: node.name,
        number: node.number,
        action: node.action,
      })
    )
    .reduce((acc, item) => acc.set(item.number, item), OrderedMap());
};
