/**
 *
 * System Options Model
 * @author Kyle Ellman
 *
 */

import Maybe from "data.maybe";
import {
  applySpec,
  compose,
  prop,
  equals,
  map,
  toUpper,
  unless,
  isNil
} from "ramda";
import { yesNoToBool, boolToYesNo } from "utils/models";

/**
 * Builds the system options model
 * @param  {object} json response from system options API
 * @return {object}
 */
export default applySpec({
  armDisarmActivityDays: compose(
    map(parseInt),
    Maybe.fromNullable,
    prop("ad_act_day")
  ),
  zoneActivityDays: compose(
    map(parseInt),
    Maybe.fromNullable,
    prop("zn_act_hrs")
  ),
  closingCheck: compose(yesNoToBool, prop("close_chk")),
  isECP: compose(equals("E"), unless(isNil, toUpper), prop("kpad_input")),
  isDSC: compose(equals("D"), unless(isNil, toUpper), prop("kpad_input"))
});

export const toApiObject = applySpec({
  close_chk: compose(boolToYesNo, prop("closingCheck"))
});
