import {
  createJson as defaultCreateJson,
  deleteJson as defaultDeleteJson,
  getJson as defaultGetJson,
  patchJson as defaultPatchJson,
  updateJson as defaultUpdateJson,
  expectSuccessWithJsonBody,
  tagNotAuthenticatedError,
} from "common/utils/secure-ui/utils/src";
import Either from "data.either";
import Maybe from "data.maybe";
import Task from "data.task";
import { chain, compose } from "ramda";
import store from "store";
import {
  LOG_IN,
  UNAUTHORIZED_RESPONSE_RECEIVED,
  UPDATE_SESSION_TOKENS,
} from "store/auth/constants";

const normalizeResult = (result) =>
  result.fold(
    ({ status, ...rest }) =>
      status === 401
        ? Task.rejected(tagNotAuthenticatedError())
        : Task.of(Either.Left({ status, ...rest })),
    (x) => Task.of(Either.of(x))
  );

export const createJson = compose(chain(normalizeResult), defaultCreateJson);
export const deleteJson = compose(chain(normalizeResult), defaultDeleteJson);
export const getJson = compose(chain(normalizeResult), defaultGetJson);
export const patchJson = compose(chain(normalizeResult), defaultPatchJson);
export const updateJson = compose(chain(normalizeResult), defaultUpdateJson);

export const getPagedJson = (
  { url, authToken, params = {} },
  page = 1,
  pageSize = 100
) =>
  expectSuccessWithJsonBody(
    (json) => (Array.isArray(json) ? Maybe.of(json) : Maybe.Nothing()),
    getJson({
      url,
      authToken,
      params: {
        ...params,
        page,
        page_size: pageSize,
      },
    })
  ).chain((results) =>
    results.length === pageSize
      ? getPagedJson(
          { url, authToken, params },
          page + 1,
          pageSize
        ).map((nextResults) => results.concat(nextResults))
      : Task.of(results)
  );

/**
 * @description When a resource is restricted, use this to avoid calling an api and "resolve" the data for a resource to empty json.
 *
 * @params normalizeJson: The resource's normalization function
 *
 * @example if(videoEnabled){
 *  callApi()
 * } else {
 *  doNotCallApi()
 * }
 */
export const doNotCallApi = (normalizeJson) =>
  expectSuccessWithJsonBody(Maybe.fromNullable, getJson())
    .orElse(() => Task.of([]))
    .map(normalizeJson);

export const dispatchLogIn = (data) => {
  store.dispatch({
    type: LOG_IN,
    email: data.email,
    authToken: data.jwt,
    refreshToken: data.refresh_token,
    authData: data,
    now: new Date(),
  });
};

export const dispatchLogOut = () => {
  store.dispatch({ type: UNAUTHORIZED_RESPONSE_RECEIVED });
};

export const dispatchUpdateSessionTokens = (authToken, refreshToken) => {
  store.dispatch({
    type: UPDATE_SESSION_TOKENS,
    authToken,
    refreshToken,
  });
};
