/**
 *
 * Event Model
 * @author Matt Shaffer, Chad Watson
 *
 */
import { OrderedMap, Record } from "immutable";
import { getSizedPhotoUrl, PHOTO_SIZES } from "utils/photos";
const createHistoryEvent = Record({
  eventId: 0,
  panelId: 0,
  createdAt: new Date().toString(),
  message: "",
  photoUrl: null,
  photoThumb: null,
  subcategoryId: null,
  eventAt: new Date().toString(),
});

const createHistoryEventFromJson = (json) =>
  createHistoryEvent({
    eventId: json.event_id,
    panelId: json.panel_id,
    createdAt: new Date(json.created_at),
    eventAt: new Date(json.event_at),
    message: json.message || "",
    photoUrl: json.size
      ? getSizedPhotoUrl(PHOTO_SIZES.mobile_medium, json.image_url)
      : json.image_url,
    photoThumb: json.size
      ? getSizedPhotoUrl(PHOTO_SIZES.thumb, json.image_url)
      : json.image_url,
    subcategoryId: json.subcategory_id,
  });

const createIndexedHistoryEvent = (map, json) =>
  map.set(json.event_id, createHistoryEventFromJson(json));

export const createHistoryEventsFromJson = (json) =>
  json.reduce(createIndexedHistoryEvent, OrderedMap());
